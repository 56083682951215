import IconButton from "components/interface/IconButton";
import {useCharacterTabs} from "contexts/CharacterTabs";
import {useCallback} from "react";
import useBEM from "hooks/useBEM";

export default function MenuBarButton({value, children, ...props}) {
  const classes = useBEM('menu-bar');
  const {activeTab, setActiveTab} = useCharacterTabs();
  const isActive = activeTab === value || activeTab.startsWith(value + '__');

  const handleClick = useCallback(() => {
    setActiveTab(value);
  }, [value, setActiveTab]);

  return <IconButton className={classes('button', {
    'active': isActive,
  })} {...props} onClick={handleClick}>
    {children}
  </IconButton>
}
