/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/hair/hair06.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "hooks/useCharacterMaterials";
import useCharacterMorphs from "hooks/useCharacterMorphs";
import {PUBLIC_URL} from "helpers/app";

export function Hair07(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/hair/hair07.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Hair07.geometry} material={characterMaterials.Hair} position={[0, 1.289, -0.098]} rotation={[0.047, 0, 0]} scale={0.922}>
        <mesh geometry={nodes.Dreadlock.geometry} material={characterMaterials.Hair} position={[0.402, -0.175, 0.062]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock001.geometry} material={characterMaterials.Hair} position={[0.379, -0.06, 0.057]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock002.geometry} material={characterMaterials.Hair} position={[0.207, 0.113, 0.048]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock003.geometry} material={characterMaterials.Hair} position={[0.009, 0.036, -0.199]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock004.geometry} material={characterMaterials.Hair} position={[0.009, -0.061, -0.256]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock005.geometry} material={characterMaterials.Hair} position={[0.272, -0.071, -0.121]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock006.geometry} material={characterMaterials.Hair} position={[0.352, -0.126, -0.086]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock007.geometry} material={characterMaterials.Hair} position={[0.404, -0.227, 0.253]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock008.geometry} material={characterMaterials.Hair} position={[0.396, -0.096, 0.156]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock009.geometry} material={characterMaterials.Hair} position={[0.278, -0.195, -0.186]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock010.geometry} material={characterMaterials.Hair} position={[0.352, -0.347, 0.034]} rotation={[-0.047, 0, 0]} scale={1.085} />
        {/*<mesh geometry={nodes.Dreadlock011.geometry} material={characterMaterials.Hair} position={[0.378, -0.354, 0.2]} rotation={[-0.047, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock012.geometry} material={characterMaterials.Hair} position={[0.281, -0.379, 0.121]} rotation={[-0.047, 0, 0]} scale={1.085} />*/}
        <mesh geometry={nodes.Dreadlock013.geometry} material={characterMaterials.Hair} position={[-0.409, -0.179, 0.063]} rotation={[3.095, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock014.geometry} material={characterMaterials.Hair} position={[-0.359, -0.129, -0.086]} rotation={[3.095, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock015.geometry} material={characterMaterials.Hair} position={[-0.409, -0.255, 0.246]} rotation={[3.095, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock016.geometry} material={characterMaterials.Hair} position={[-0.403, -0.099, 0.17]} rotation={[3.095, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock017.geometry} material={characterMaterials.Hair} position={[-0.285, -0.199, -0.186]} rotation={[3.095, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock018.geometry} material={characterMaterials.Hair} position={[-0.359, -0.35, 0.034]} rotation={[3.095, 0, 0]} scale={1.085} />
        {/*<mesh geometry={nodes.Dreadlock019.geometry} material={characterMaterials.Hair} position={[-0.385, -0.358, 0.2]} rotation={[3.095, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock020.geometry} material={characterMaterials.Hair} position={[-0.288, -0.382, 0.121]} rotation={[3.095, 0, 0]} scale={1.085} />*/}
        <mesh geometry={nodes.Dreadlock021.geometry} material={characterMaterials.Hair} position={[-0.359, -0.208, 0.027]} rotation={[3.095, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock022.geometry} material={characterMaterials.Hair} position={[-0.252, 0.038, -0.063]} rotation={[3.095, 0, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock023.geometry} material={characterMaterials.Hair} position={[-0.269, -0.375, 0.284]} rotation={[3.095, -0.27, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock024.geometry} material={characterMaterials.Hair} position={[-0.175, -0.372, 0.352]} rotation={[3.095, -0.91, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock025.geometry} material={characterMaterials.Hair} position={[-0.049, -0.378, 0.376]} rotation={[3.095, -1.283, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock026.geometry} material={characterMaterials.Hair} position={[0.089, -0.378, 0.376]} rotation={[3.095, -1.513, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock027.geometry} material={characterMaterials.Hair} position={[0.212, -0.381, 0.318]} rotation={[-0.047, -1.222, -Math.PI]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock028.geometry} material={characterMaterials.Hair} position={[0.323, -0.386, 0.224]} rotation={[-0.047, -1.101, -Math.PI]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock029.geometry} material={characterMaterials.Hair} position={[-0.32, -0.227, 0.338]} rotation={[3.095, -0.283, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock030.geometry} material={characterMaterials.Hair} position={[-0.239, -0.246, 0.418]} rotation={[3.095, -0.891, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock031.geometry} material={characterMaterials.Hair} position={[0.012, -0.244, 0.475]} rotation={[3.095, -1.362, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock032.geometry} material={characterMaterials.Hair} position={[0.143, -0.222, 0.443]} rotation={[3.095, -1.476, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock033.geometry} material={characterMaterials.Hair} position={[0.256, -0.247, 0.405]} rotation={[-0.047, -1.215, -Math.PI]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock034.geometry} material={characterMaterials.Hair} position={[-0.115, -0.221, 0.457]} rotation={[3.095, -1.129, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock035.geometry} material={characterMaterials.Hair} position={[-0.354, -0.131, 0.312]} rotation={[3.095, -0.406, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock036.geometry} material={characterMaterials.Hair} position={[-0.252, -0.05, 0.371]} rotation={[3.095, -0.568, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock037.geometry} material={characterMaterials.Hair} position={[-0.153, -0.081, 0.425]} rotation={[3.095, -1.074, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock038.geometry} material={characterMaterials.Hair} position={[-0.029, -0.032, 0.422]} rotation={[3.095, -1.365, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock039.geometry} material={characterMaterials.Hair} position={[0.228, -0.065, 0.391]} rotation={[-0.047, -1.209, -Math.PI]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock040.geometry} material={characterMaterials.Hair} position={[0.313, -0.052, 0.325]} rotation={[-0.047, -1.052, -Math.PI]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock041.geometry} material={characterMaterials.Hair} position={[0.093, -0.093, 0.425]} rotation={[-0.047, -1.444, -Math.PI]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock042.geometry} material={characterMaterials.Hair} position={[-0.232, 0.105, 0.099]} rotation={[3.095, -0.206, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock043.geometry} material={characterMaterials.Hair} position={[-0.145, 0.106, 0.195]} rotation={[3.095, -0.426, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock044.geometry} material={characterMaterials.Hair} position={[0.231, 0.071, 0.179]} rotation={[-0.047, -0.955, -Math.PI]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock045.geometry} material={characterMaterials.Hair} position={[-0.111, 0.066, 0.441]} rotation={[3.095, -0.949, 0]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock046.geometry} material={characterMaterials.Hair} position={[0.23, 0.065, 0.419]} rotation={[-0.047, -1.453, -Math.PI]} scale={1.085} />
        <mesh geometry={nodes.Dreadlock047.geometry} material={characterMaterials.Hair} position={[0.042, 0.113, 0.27]} rotation={[3.095, -1.238, 0]} scale={1.085} />
      </mesh>
    </group>
  )
}

useGLTF.preload(PUBLIC_URL + '/assets/models/hair/hair07.glb')
