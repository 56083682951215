import "styles/components/character-app/_character-options.scss";
import {useCharacterParams} from "contexts/CharacterParams";
import {useCallback} from "react";
import IconButton from "components/interface/IconButton";
import useBEM from "hooks/useBEM";

function CharacterOption({option, selected, onClick}) {
  const classes = useBEM('character-options');
  const isActive = selected === option.value;

  const handleClick = useCallback(() => {
    onClick && onClick(option.value);
  }, [onClick, option.value]);

  return <IconButton className={classes('item', {
    active: isActive,
  })} onClick={handleClick}>{typeof option.icon === 'string' ? <img src={option.icon} alt={option.title} width={100} height={100} draggable={false}/> : option.icon}</IconButton>
}

export default function CharacterOptions({name, options = []}) {
  const classes = useBEM('character-options');
  const {parameters, setParameter} = useCharacterParams();
  const value = parameters ? parameters[name] : null;

  const handleClick = useCallback((newValue) => {
    if (newValue !== value) {
      setParameter(name, newValue);
    }
  }, [name, value, setParameter]);

  return <div className={classes({
    name,
  })}>
    {options.map(option => <CharacterOption key={option.value} option={option} selected={value} onClick={handleClick}/>)}
  </div>
}
