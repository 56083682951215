import useBEM from "hooks/useBEM";
import SecondaryTabs from "components/CharacterApp/interface/CharacterTabs/SecondaryTabs";
import {useCharacterTabs} from "contexts/CharacterTabs";

export default function RenderPreview() {
  const classes = useBEM('character-app');
  const {activeTab} = useCharacterTabs();

  return activeTab === 'save__render' && <div className={classes('preview')}>
    <SecondaryTabs/>
  </div>
}
