import "styles/components/character-app/_name-tag.scss";
import useBEM from "hooks/useBEM";
import {useCharacterParams} from "contexts/CharacterParams";
import {useMemo} from "react";

const NameTag = () => {
  const classes = useBEM('name-tag');
  const {character, name, tagline} = useCharacterParams().parameters;

  const displayName = useMemo(() => {
    if (name) {
      return name;
    }

    switch (character) {
      default:
        return '&nbsp;';
      case 'KidAltrini':
        return 'Kid Altrini';
      case 'CaptainAltrini':
        return 'Captain Altrini';
      case 'AltriniGirl':
        return 'Altrini Girl';
      case 'MissAltrini':
        return 'Miss Altrini';
    }
  }, [character, name]);

  const displayTagline = useMemo(() => {
    if (tagline) {
      return tagline;
    }

    return 'Fuelling futures, one bottle at a time!';
  }, [tagline]);

  return <div className={classes()}>
    <div className={classes('name')}>{displayName}</div>
    <div className={classes('tagline')}>{displayTagline}</div>
  </div>
}

export default NameTag;
