import useBEM from "hooks/useBEM";
import {useCharacterTabs} from "contexts/CharacterTabs";
import {useCharacterParams} from "contexts/CharacterParams";
import {lazy, useMemo, Suspense} from "react";

const BottleEmpty = lazy(() => import('icons/bottle/BottleEmpty'));
const Bottle1 = lazy(() => import('icons/bottle/Bottle1'));
const Bottle2 = lazy(() => import('icons/bottle/Bottle2'));
const Bottle3 = lazy(() => import('icons/bottle/Bottle3'));
const Bottle4 = lazy(() => import('icons/bottle/Bottle4'));
const Bottle5 = lazy(() => import('icons/bottle/Bottle5'));
const BottleFull = lazy(() => import('icons/bottle/BottleFull'));

const PowersBottle = () => {
  const classes = useBEM('powers-bottle');
  const {activeTab} = useCharacterTabs();
  const {parameters} = useCharacterParams();
  const values = useMemo(() => parameters?.powers || {}, [parameters.powers]);
  const used = useMemo(() => {
    return Object.values(values).reduce((partialSum, value) => partialSum + value, 0);
  }, [values]);

  const bottle = useMemo(() => {
    switch (used) {
      default: return <BottleEmpty/>
      case 1: return <Bottle1/>
      case 2: return <Bottle2/>
      case 3: return <Bottle3/>
      case 4: return <Bottle4/>
      case 5: return <Bottle5/>
      case 6: return <BottleFull/>
    }
  }, [used]);

  return activeTab === 'powers' && <div className={classes()}>
    <Suspense>
      {bottle}
    </Suspense>
  </div>
}

export default PowersBottle;
