import {useEffect, useMemo} from "react";
import {useCharacterParams} from "contexts/CharacterParams";
import {useThree} from "@react-three/fiber";

export default function useCharacterMaterials(materials) {
  const { invalidate } = useThree();
  const { colors } = useCharacterParams();
  const mapping = useMemo(() => {
    if (!colors) return [];
    return [
      {
        material: 'Skin',
        color: colors['skin'],
      },
      {
        material: 'Hair',
        color: colors['hair'],
      },
      {
        material: 'Eyeris',
        color: colors['eye'],
      },
      {
        material: 'MaskPrimary',
        color: colors['mask'],
      },
      {
        material: 'SuitPrimary',
        color: colors['primary'],
      },
      {
        material: 'SuitPrimaryDark',
        color: colors['primary_dark'],
      },
      {
        material: 'SuitSecondary',
        color: colors['secondary'],
      },
      {
        material: 'SuitSecondaryDark',
        color: colors['secondary_dark'],
      },
      {
        material: 'SuitBadge',
        color: colors['badge'],
      },
      {
        material: 'CapePrimary',
        color: colors['cape'],
      },
      {
        material: 'Accessory',
        color: colors['accessory'],
      },
    ];
  }, [colors]);

  useEffect(() => {
    if (materials) {
      Object.keys(materials).forEach(key => {
        // Force single sided materials.
        materials[key].side = 0;
      });

      mapping.forEach(map => {
        if (materials[map.material] && map.color) {
          map.color.color && materials[map.material].color.set(map.color.color);
          materials[map.material].metalness = map.color.metallic ? map.color.metallic : 0;
        }
      });
      invalidate();
    }
  }, [materials, mapping, invalidate]);

  return materials;
}

export const useSuitColors = () => {
  return useMemo(() => {
    return [
      {
        color: '#2FB1E7',
      },
      {
        color: '#E7A900',
      },
      {
        color: '#E70005',
      },
      {
        color: '#0D3E66',
      },
      {
        color: '#007A45',
      },
      {
        color: '#920261',
      },
      {
        color: '#F7AFBC',
      },
      {
        color: '#FABCF4',
      },
      {
        color: '#D9D9D9',
      },
      {
        color: '#333333',
      },
      {
        color: '#56AC00',
      },
      {
        color: '#8AD4FF',
      },
    ];
  }, []);
}
