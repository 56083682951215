/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/masks/mask-full-head.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import {MaskEyes01} from "components/CharacterApp/masks/MaskEyes01";
import useCharacterMorphs from "hooks/useCharacterMorphs";
import useCharacterMaterials from "hooks/useCharacterMaterials";
import {PUBLIC_URL} from "helpers/app";

export function MaskFullHead(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/masks/mask-full-head.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);
  
  return (
    <group {...props} dispose={null}>
      <mesh name="MaskFullHead" geometry={nodes.MaskFullHead.geometry} material={characterMaterials.SuitPrimary} morphTargetDictionary={nodes.MaskFullHead.morphTargetDictionary} morphTargetInfluences={nodes.MaskFullHead.morphTargetInfluences} position={[0, 1.289, -0.098]} rotation={[0.047, 0, 0]} scale={0.922} />
      <MaskEyes01/>
    </group>
  )
}

useGLTF.preload(PUBLIC_URL + '/assets/models/masks/mask-full-head.glb')
