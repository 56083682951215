import useBEM, {useFormBEM} from "hooks/useBEM";
import {Radio as CoreRadio} from "@mantine/core";

export function Radio(props) {
  const classes = useBEM('radio');
  const classNames = useFormBEM('radio');

  return <CoreRadio classNames={{
    ...classNames,
    radio: classes('input', {}, ['form-item__input']),
    icon: classes('icon'),
    inner: classes('inner'),
    body: classes('body'),
    labelWrapper: classes('label-wrapper'),
  }} {...props}/>
}

export function RadioGroup(props) {
  const classNames = useFormBEM('radios');

  return <CoreRadio.Group classNames={{
    ...classNames,
  }} {...props}/>
}
