import apiService from "helpers/apiService";

const getHero = async (uuid) => {
  const response = await apiService.getClient().request({
    url: `/hero/${uuid}`,
    method: 'GET',
  })
  return response.data
}

const addHero = async (data, options = {}) => {
  const response = await apiService.getClient().request({
    url: `/hero`,
    method: 'POST',
    data: data,
    ...options,
  })
  return response.data
}

export const heroService = {
  getHero,
  addHero,
};
