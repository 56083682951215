import "styles/components/_button.scss";
import {Button as CoreButton} from "@mantine/core";
import useBEM from "hooks/useBEM";

export default function Button(params) {
  const classes = useBEM('button');
  const modifiers = {};
  if (params.variant) {
    modifiers[params.variant] = true;
  }

  if (params.fullWidth) {
    modifiers['full-width'] = true;
  }

  return <CoreButton classNames={{
    root: classes(modifiers),
    inner: classes('inner'),
    label: classes('label'),
  }} {...params}/>
}
