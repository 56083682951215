import "styles/components/character-app/_menu-bar.scss";
import useBEM from "hooks/useBEM";
import MenuBarButton from "components/CharacterApp/interface/MenuBar/MenuBarButton";
import HeroIcon from "icons/HeroIcon";
import ColorIcon from "icons/ColorIcon";
import HairIcon from "icons/HairIcon";
import EyeIcon from "icons/EyeIcon";
import MaskIcon from "icons/MaskIcon";
import CapeIcon from "icons/CapeIcon";
import HeadphonesIcon from "icons/HeadphonesIcon";
import OutfitIcon from "icons/OutfitIcon";
import BadgeIcon from "icons/BadgeIcon";
import TextIcon from "icons/TextIcon";
import PowersIcon from "icons/PowersIcon";
import SaveIcon from "icons/SaveIcon";
import MenuBarGroup from "components/CharacterApp/interface/MenuBar/MenuBarGroup";
import ScrollArea from "components/interface/ScrollArea";

export default function MenuBar() {
  const classes = useBEM('menu-bar');
  return <div className={classes()}>
    <ScrollArea scrollbars={'x'} type={'auto'}>
      <div className={classes('inner')}>
        <MenuBarGroup>
          <MenuBarButton value={'character'}>
            <HeroIcon/>
          </MenuBarButton>
          <MenuBarButton value={'character_color'}>
            <ColorIcon/>
          </MenuBarButton>
        </MenuBarGroup>

        <MenuBarGroup>
          <MenuBarButton value={'hair'}>
            <HairIcon/>
          </MenuBarButton>
          <MenuBarButton value={'hair_color'}>
            <ColorIcon/>
          </MenuBarButton>
        </MenuBarGroup>

        <MenuBarButton value={'eyes'}>
          <EyeIcon/>
        </MenuBarButton>

        <MenuBarGroup>
          <MenuBarButton value={'mask'}>
            <MaskIcon/>
          </MenuBarButton>
          <MenuBarButton value={'mask_color'}>
            <ColorIcon/>
          </MenuBarButton>
        </MenuBarGroup>

        <MenuBarGroup>
          <MenuBarButton value={'cape'}>
            <CapeIcon/>
          </MenuBarButton>
          <MenuBarButton value={'cape_color'}>
            <ColorIcon/>
          </MenuBarButton>
        </MenuBarGroup>

        <MenuBarGroup>
          <MenuBarButton value={'accessory'}>
            <HeadphonesIcon/>
          </MenuBarButton>
          <MenuBarButton value={'accessory_color'}>
            <ColorIcon/>
          </MenuBarButton>
        </MenuBarGroup>

        <MenuBarButton value={'outfit'}>
          <OutfitIcon/>
        </MenuBarButton>

        <MenuBarButton value={'badge'}>
          <BadgeIcon/>
        </MenuBarButton>

        <MenuBarButton value={'name'}>
          <TextIcon/>
        </MenuBarButton>
        <MenuBarButton value={'powers'}>
          <PowersIcon/>
        </MenuBarButton>

        <MenuBarButton value={'save'}>
          <SaveIcon/>
        </MenuBarButton>
      </div>
    </ScrollArea>
  </div>
}