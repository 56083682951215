import {useThree} from "@react-three/fiber";
import {useEffect, useState} from "react";
import {easeInOutSine} from "helpers/app";

export default function IdleAnimation({fps = 60, min = 0, max = 1, time = 1000, children}) {
  const {invalidate} = useThree();
  const [ref, setRef] = useState(null);

  useEffect(() => {
    let id;
    if (ref) {
      invalidate();
      requestAnimationFrame(() => {
        ref.position.y = min;
        id = easeInOutSine(min, max, time, fps, (delta) => {
          ref.position.y = ref.position.y + delta;
          invalidate();
        });
      });
    }

    return () => {
      if (ref) {
        ref.position.y = 0;
      }
      clearInterval(id);
    }
  }, [ref, fps, invalidate, max, min, time]);

  return <group ref={setRef}>
    {children}
  </group>
}