export default function BadgeIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.6835 42.7301L42.1394 38.6713H28.9923L27.4482 42.8184C26.8453 44.436 26.3306 45.5316 25.9041 46.1051C25.4776 46.664 24.7791 46.9434 23.8085 46.9434C22.985 46.9434 22.257 46.6419 21.6247 46.039C20.9923 45.436 20.6761 44.7522 20.6761 43.9875C20.6761 43.5463 20.7497 43.0904 20.8967 42.6198C21.0438 42.1493 21.2864 41.4948 21.6247 40.6566L29.8967 19.6566C30.132 19.0537 30.4114 18.3331 30.735 17.4948C31.0732 16.6419 31.4261 15.936 31.7938 15.3772C32.1761 14.8184 32.6688 14.3698 33.2717 14.0316C33.8894 13.6787 34.6467 13.5022 35.5438 13.5022C36.4556 13.5022 37.2129 13.6787 37.8159 14.0316C38.4335 14.3698 38.9261 14.811 39.2938 15.3551C39.6761 15.8993 39.9923 16.4875 40.2423 17.1198C40.507 17.7375 40.8379 18.5684 41.235 19.6125L49.6835 40.4801C50.3453 42.0684 50.6761 43.2228 50.6761 43.9434C50.6761 44.6934 50.36 45.3846 49.7276 46.0169C49.11 46.6346 48.36 46.9434 47.4776 46.9434C46.9629 46.9434 46.5217 46.8478 46.1541 46.6566C45.7864 46.4801 45.4776 46.2375 45.2276 45.9287C44.9776 45.6051 44.7056 45.1198 44.4114 44.4728C44.132 43.811 43.8894 43.2301 43.6835 42.7301ZM30.7129 33.7522H40.3747L35.4997 20.4066L30.7129 33.7522Z"
      fill="#696969"/>
    <path
      d="M43.6835 41.8266L42.1394 37.7678H28.9923L27.4482 41.9148C26.8453 43.5325 26.3306 44.628 25.9041 45.2016C25.4776 45.7604 24.7791 46.0398 23.8085 46.0398C22.985 46.0398 22.257 45.7383 21.6247 45.1354C20.9923 44.5325 20.6761 43.8486 20.6761 43.0839C20.6761 42.6428 20.7497 42.1869 20.8967 41.7163C21.0438 41.2457 21.2864 40.5913 21.6247 39.753L29.8967 18.753C30.132 18.1501 30.4114 17.4295 30.735 16.5913C31.0732 15.7383 31.4261 15.0325 31.7938 14.4736C32.1761 13.9148 32.6688 13.4663 33.2717 13.128C33.8894 12.7751 34.6467 12.5986 35.5438 12.5986C36.4556 12.5986 37.2129 12.7751 37.8159 13.128C38.4335 13.4663 38.9261 13.9075 39.2938 14.4516C39.6761 14.9957 39.9923 15.5839 40.2423 16.2163C40.507 16.8339 40.8379 17.6648 41.235 18.7089L49.6835 39.5766C50.3453 41.1648 50.6761 42.3192 50.6761 43.0398C50.6761 43.7898 50.36 44.481 49.7276 45.1133C49.11 45.731 48.36 46.0398 47.4776 46.0398C46.9629 46.0398 46.5217 45.9442 46.1541 45.753C45.7864 45.5766 45.4776 45.3339 45.2276 45.0251C44.9776 44.7016 44.7056 44.2163 44.4114 43.5692C44.132 42.9075 43.8894 42.3266 43.6835 41.8266ZM30.7129 32.8486H40.3747L35.4997 19.503L30.7129 32.8486Z"
      fill="white"/>
  </svg>
}
