import Button from "components/interface/Button";
import AddBoxIcon from "icons/AddBoxIcon";
import {useCallback, useEffect, useMemo, useState} from "react";
import {heroService} from "helpers/heroService";
import {useCharacterParams} from "contexts/CharacterParams";
import {useCharacterExport} from "contexts/CharacterExport";
import {blobToBase64, exportHero, renderHero} from "helpers/app";

const WallButton = () => {
  const {parameters, colors} = useCharacterParams();
  const {scene} = useCharacterExport();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const canSubmit = useMemo(() => {
    if (!parameters.name || !parameters.tagline || !parameters.powers || !colors) {
      return false;
    }

    return true;
  }, [parameters, colors]);

  const handleWallSave = useCallback(async () => {
    setLoading(true);
    try {
      setStatusMessage('Exporting files...');
      const heroImage = await blobToBase64(await renderHero(scene, false));
      const gltf = await blobToBase64(await exportHero(scene, 'gltf'));
      const usdz = await blobToBase64(await exportHero(scene, 'usdz'));

      heroService.addHero({
        name: [parameters.name],
        tagline: [parameters.tagline],
        config: [{
          value: {
            ...parameters,
            colors,
          },
        }],
        image: [{
          data: heroImage.data,
          filemime: heroImage.filemime,
          extension: 'png',
        }],
        gltf: [{
          data: gltf.data,
          filemime: gltf.filemime,
          extension: 'gltf',
        }],
        usdz: [{
          data: usdz.data,
          filemime: usdz.filemime,
          extension: 'usdz',
        }],
      }, {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength) {
            setStatusMessage('Uploading: ' + Math.round(progressEvent.loaded / totalLength * 100) + '%');
          }
        },
      }).then(() => {
        setLoading(false);
        setSuccess(true);
      }).catch((e) => {
        setLoading(false);
        console.error(e);
      });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [scene, parameters, colors]);

  useEffect(() => {
    setSuccess(false);
  }, [parameters, colors]);

  return canSubmit && <Button disabled={isLoading || isSuccess} onClick={handleWallSave} variant={'square'} fullWidth
                              leftSection={<AddBoxIcon/>}>
    {isLoading ? statusMessage : (isSuccess ? 'Your hero was added' : 'Add to hero wall')}
  </Button>
}

export default WallButton;
