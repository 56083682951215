import useBEM from "hooks/useBEM";
import ButtonGroup from "components/interface/ButtonGroup";
import {Children, useMemo} from "react";
import {useCharacterTabs} from "contexts/CharacterTabs";
import MenuBarButton from "components/CharacterApp/interface/MenuBar/MenuBarButton";

export default function MenuBarGroup({children}) {
  const classes = useBEM('menu-bar');
  const {activeTab} = useCharacterTabs();
  const isActive = useMemo(() => {
    let hasActiveChildren = false;
    Children.forEach(children, (child) => {
      if (child.type === MenuBarButton && child.props.value === activeTab) {
        hasActiveChildren = true;
      }
    });

    return hasActiveChildren;
  }, [activeTab, children]);

  return <ButtonGroup className={classes('group', {
    active: isActive,
  })}>
    {children}
  </ButtonGroup>
}
