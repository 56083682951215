import {createContext, useContext, useState} from "react";

export const CharacterExportContext = createContext({});

export const CharacterExportProvider = (props) => {
  const [scene, setScene] = useState();

  return <CharacterExportContext.Provider value={{
    scene,
    setScene,
  }}>
    {props.children}
  </CharacterExportContext.Provider>
}

export const useCharacterExport = () => {
  return useContext(CharacterExportContext);
};
