import ColorPicker from "components/CharacterApp/interface/ColorPicker";
import {useCallback, useMemo, useState} from "react";
import {useCharacterParams} from "contexts/CharacterParams";
import {Tabs, TabsList, Tab, TabsPanel} from "components/interface/Tabs";
import {useSuitColors} from "hooks/useCharacterMaterials";
import CharacterTabsPanel, {
  CharacterTabsPanelFooter,
  CharacterTabsPanelHeader
} from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function OutfitColorTab() {
  const {colors, setColor} = useCharacterParams();
  const availableColors = useSuitColors();
  const [activeTab, setActiveTab] = useState('primary');

  const handlePrimaryChange = useCallback((color) => {
    setColor('primary', color);
  }, [setColor]);

  const handleSecondaryChange = useCallback((color) => {
    setColor('secondary', color);
  }, [setColor]);

  const tabs = useMemo(() => {
    return [
      {
        value: 'primary',
        color: colors.primary,
        onChange: handlePrimaryChange,
      },
      {
        value: 'secondary',
        color: colors.secondary,
        onChange: handleSecondaryChange,
      }
    ];
  }, [colors.primary, colors.secondary, handlePrimaryChange, handleSecondaryChange]);

  return <CharacterTabsPanel value={'outfit'} className={'character-tabs__panel--with-tabs'}>
    <CharacterTabsPanelHeader>
      <Tabs value={activeTab} onChange={setActiveTab}>
        <TabsList>
          <Tab value={'primary'}>Primary</Tab>
          <Tab value={'secondary'}>Secondary</Tab>
        </TabsList>
      </Tabs>
    </CharacterTabsPanelHeader>

    <Tabs value={activeTab}>
      {tabs.map(tab => {
        return <TabsPanel value={tab.value} key={tab.value}>
          <ColorPicker colors={availableColors} color={tab.color} onChange={tab.onChange} allowSlider={false}/>
        </TabsPanel>
      })}
    </Tabs>

    <CharacterTabsPanelFooter>
      <Tabs value={activeTab}>
        {tabs.map(tab => {
          return <TabsPanel value={tab.value} key={tab.value}>
            <ColorPicker colors={availableColors} color={tab.color} onChange={tab.onChange} allowSwatches={false}/>
          </TabsPanel>
        })}
      </Tabs>
    </CharacterTabsPanelFooter>
  </CharacterTabsPanel>
}
