export default function SaveIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.2006 19.6345C42.2006 20.2492 41.9372 20.776 41.586 21.215C40.7079 22.0931 39.3908 22.0931 38.5128 21.215L37.2835 19.9858V35.7906C37.2835 37.0199 36.3177 37.9858 35.0884 37.9858C33.8591 37.9858 32.8933 37.0199 32.8933 35.7906V19.9858L31.664 21.215C30.786 22.0931 29.4689 22.0931 28.5908 21.215C27.7128 20.337 27.7128 19.0199 28.5908 18.1419L33.5957 13.137C34.0348 12.6979 34.5616 12.5223 35.1762 12.5223H35.264C35.7908 12.5223 36.3177 12.7858 36.7567 13.137L41.7616 18.1419C42.2006 18.5809 42.3762 19.1077 42.3762 19.7223L42.2006 19.6345Z"
      fill="#696969"/>
    <path
      d="M53 30.5229V44.5717C53 45.1863 52.7366 45.7131 52.3854 46.1521C51.9463 46.5912 51.4195 46.7668 50.8049 46.7668H19.1951C18.5805 46.7668 18.0537 46.5034 17.6146 46.1521C17.1756 45.7131 17 45.1863 17 44.5717V30.5229C17 29.2936 17.9659 28.3278 19.1951 28.3278C20.4244 28.3278 21.3902 29.2936 21.3902 30.5229V42.3765H48.6098V30.5229C48.6098 29.2936 49.5756 28.3278 50.8049 28.3278C52.0341 28.3278 53 29.2936 53 30.5229Z"
      fill="#696969"/>
    <path
      d="M42.2006 18.6771C42.2006 19.2918 41.9372 19.8186 41.586 20.2576C40.7079 21.1357 39.3908 21.1357 38.5128 20.2576L37.2835 19.0284V34.8332C37.2835 36.0625 36.3177 37.0284 35.0884 37.0284C33.8591 37.0284 32.8933 36.0625 32.8933 34.8332V19.0284L31.664 20.2576C30.786 21.1357 29.4689 21.1357 28.5908 20.2576C27.7128 19.3796 27.7128 18.0625 28.5908 17.1845L33.5957 12.1796C34.0348 11.7406 34.5616 11.5649 35.1762 11.5649H35.264C35.7908 11.5649 36.3177 11.8284 36.7567 12.1796L41.7616 17.1845C42.2006 17.6235 42.3762 18.1503 42.3762 18.7649L42.2006 18.6771Z"
      fill="white"/>
    <path
      d="M53 29.5656V43.6144C53 44.229 52.7366 44.7558 52.3854 45.1949C51.9463 45.6339 51.4195 45.8095 50.8049 45.8095H19.1951C18.5805 45.8095 18.0537 45.5461 17.6146 45.1949C17.1756 44.7558 17 44.229 17 43.6144V29.5656C17 28.3363 17.9659 27.3705 19.1951 27.3705C20.4244 27.3705 21.3902 28.3363 21.3902 29.5656V41.4193H48.6098V29.5656C48.6098 28.3363 49.5756 27.3705 50.8049 27.3705C52.0341 27.3705 53 28.3363 53 29.5656Z"
      fill="white"/>
  </svg>
}
