import { withNaming } from '@bem-react/classname';
import {useMemo} from "react";
const cn = withNaming({ e: '__', m: '--', v: '-' });

export default function useBEM(className) {
  return cn(className);
}

export function useFormBEM(className) {
  const classes = useBEM(className);
  const itemClasses = useBEM('form-item');

  return useMemo(() => {
    return {
      root: classes({}, [itemClasses()]),
      label: classes('label', {}, [itemClasses('label')]),
      required: classes('error', {}, [itemClasses('required')]),
      error: classes('error', {}, [itemClasses('error')]),
      description: classes('description', {}, [itemClasses('description')]),
      wrapper: classes('wrapper', {}, [itemClasses('wrapper')]),
      input: classes('input', {}, [itemClasses('input')]),
      section: classes('section', {}, [itemClasses('section')]),
    };
  }, [classes, itemClasses]);
}
