import {useCharacterParams} from "contexts/CharacterParams";
import {useCharacterExport} from "contexts/CharacterExport";
import {useCallback} from "react";

export default function Character({isMain = false}) {
  const {setScene} = useCharacterExport();
  const setExportScene = useCallback((scene) => {
    if (isMain && typeof setScene === 'function') {
      setScene(scene);
    }
  }, [isMain, setScene]);

  const { character, showHair, hair, mask, cape, accessory } = useCharacterParams();
  return <group position={[0, 0, 0]} ref={setExportScene}>
    <group rotation={[0, Math.PI, 0]}>
      {character}
      {showHair && hair}
      {mask}
      {cape}
      {accessory}
    </group>
  </group>
}
