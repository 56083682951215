import {useCharacterParams} from "contexts/CharacterParams";
import {useEffect} from "react";

export default function useCharacterMorphs(nodes) {
  const { parameters } = useCharacterParams();

  useEffect(() => {
    Object.keys(nodes).forEach((id) => {
      const node = nodes[id];
      if (node?.morphTargetDictionary) {
        Object.keys(node.morphTargetDictionary).forEach((morphName) => {
          const morphKey = node.morphTargetDictionary[morphName];
          const keyParts = morphName.split('_');
          const param = parameters[keyParts[0]];

          if ( typeof param !== 'undefined' ) {
            node.morphTargetInfluences[morphKey] = (param === keyParts[1]) ? 1 : 0;
          }
        });
      }
    });
  }, [nodes, parameters]);
}
