import React from "react";
import ReactDOM from "react-dom/client";
import './styles/global.scss';
import App from "./App";
import { HeadlessMantineProvider } from "@mantine/core";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HeadlessMantineProvider>
      <App />
    </HeadlessMantineProvider>
  </React.StrictMode>
);

const handleUpdate = (registration) => {
  registration.waiting.postMessage({type: 'SKIP_WAITING'});
  window.location.reload();
}
serviceWorkerRegistration.register({
  onRegister: (registration) => {
    if (registration.waiting) {
      handleUpdate(registration);
    }
  },
  onUpdate: (registration) => {
    handleUpdate(registration);
  }
});
