import {useMemo} from "react";
import CharacterOptions from "components/CharacterApp/interface/CharacterOptions";
import NoCapeIcon from "images/character-app/no-cape.png";
import CapeIcon from "images/character-app/cape.png";
import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function CapeTab() {
  const options = useMemo(() => {
    return [
      {
        value: false,
        title: 'No cape',
        icon: NoCapeIcon,
      },
      {
        value: true,
        title: 'Cape',
        icon: CapeIcon,
      },
    ];
  }, []);

  return <CharacterTabsPanel value={'cape'}>
    <CharacterOptions name={'cape'} options={options}/>
  </CharacterTabsPanel>
}
