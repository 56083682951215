import ColorPicker from "components/CharacterApp/interface/ColorPicker";
import {useCallback} from "react";
import {useCharacterParams} from "contexts/CharacterParams";
import {useSuitColors} from "hooks/useCharacterMaterials";
import CharacterTabsPanel, {CharacterTabsPanelFooter} from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function CapeColorTab() {
  const { colors, setColor } = useCharacterParams();
  const availableColors = useSuitColors();

  const handleChange = useCallback((color) => {
    setColor('cape', color);
  }, [setColor]);

  return <CharacterTabsPanel value={'cape_color'}>
    <ColorPicker colors={availableColors} color={colors.cape} onChange={handleChange} allowSlider={false}/>
    <CharacterTabsPanelFooter>
      <ColorPicker colors={availableColors} color={colors.cape} onChange={handleChange} allowSwatches={false}/>
    </CharacterTabsPanelFooter>
  </CharacterTabsPanel>
}
