import {Tabs} from "@mantine/core";
import useBEM from "hooks/useBEM";
import ScrollArea from "components/interface/ScrollArea";
import {Children, useMemo} from "react";

export function CharacterTabsPanelFooter({children}) {
  const classes = useBEM('character-tabs');
  return children && <div className={classes('panel-footer')}>
    {children}
  </div>
}

export function CharacterTabsPanelHeader({children}) {
  const classes = useBEM('character-tabs');
  return children && <div className={classes('panel-header')}>
    {children}
  </div>
}

export default function CharacterTabsPanel({children, className, ...params}) {
  const classes = useBEM('character-tabs');
  const header = useMemo(() => {
    return Children.map(children, child => child.type?.displayName === 'CharacterTabsPanelHeader' ? child : null) || [];
  }, [children]);
  const footer = useMemo(() => {
    return Children.map(children, child => child.type?.displayName === 'CharacterTabsPanelFooter' ? child : null) || [];
  }, [children]);
  const content = useMemo(() => {
    return Children.map(children, child => child.type && !['CharacterTabsPanelHeader', 'CharacterTabsPanelFooter'].includes(child.type.displayName) ? child : null);
  }, [children]);

  return <Tabs.Panel className={classes('panel', {
    'with-header': header.length > 0,
    'with-footer': footer.length > 0,
    name: params.value,
  }, [className])} {...params}>
    {header}
    <ScrollArea className={classes('panel-content')}>
      {content}
    </ScrollArea>
    {footer}
  </Tabs.Panel>
}

CharacterTabsPanelHeader.displayName = 'CharacterTabsPanelHeader';
CharacterTabsPanelFooter.displayName = 'CharacterTabsPanelFooter';
