import ColorPicker from "components/CharacterApp/interface/ColorPicker";
import {useCallback, useMemo} from "react";
import {useCharacterParams} from "contexts/CharacterParams";
import CharacterTabsPanel, {CharacterTabsPanelFooter} from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function EyeColorTab() {
  const { colors, setColor } = useCharacterParams();
  const availableColors = useMemo(() => {
    return [
      {
        color: '#4B5552',
        title: 'Grey eyes',
      },
      {
        color: '#2A7B94',
        title: 'Blue eyes',
      },
      {
        color: '#396F3C',
        title: 'Green eyes',
      },
      {
        color: '#7B520E',
        title: 'Amber eyes',
      },
      {
        color: '#512816',
        title: 'Brown eyes',
      },
      {
        color: '#2B1811',
        title: 'Dark brown eyes',
      },
    ];
  }, []);

  const handleChange = useCallback((color) => {
    setColor('eye', color);
  }, [setColor]);

  return <CharacterTabsPanel value={'eyes'}>
    <ColorPicker colors={availableColors} color={colors.eye} onChange={handleChange} allowMetallic={false} allowSlider={false}/>
    <CharacterTabsPanelFooter>
      <ColorPicker colors={availableColors} color={colors.eye} onChange={handleChange} allowMetallic={false} allowSwatches={false}/>
    </CharacterTabsPanelFooter>
  </CharacterTabsPanel>
}
