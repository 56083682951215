import ShareIcon from "icons/ShareIcon";
import Button from "components/interface/Button";
import {useCharacterTabs} from "contexts/CharacterTabs";

const RenderButton = () => {
  const {setActiveTab} = useCharacterTabs();
  return <Button onClick={() => setActiveTab('save__render')} variant={'square'} fullWidth leftSection={<ShareIcon/>}>Share your character</Button>
}

export default RenderButton;
