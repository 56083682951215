/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/accessories/cape.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "../../../hooks/useCharacterMaterials";
import useCharacterMorphs from "../../../hooks/useCharacterMorphs";
import {PUBLIC_URL} from "helpers/app";

export function Cape(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/accessories/cape.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);
  
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0.723, -0.132]}>
        <mesh geometry={nodes.Cape_1.geometry} material={characterMaterials.CapePrimary} />
        <mesh geometry={nodes.Cape_2.geometry} material={characterMaterials.CapeSecondary} />
      </group>
    </group>
  )
}

useGLTF.preload(PUBLIC_URL + '/assets/models/accessories/cape.glb')
