import CharacterOptions from "components/CharacterApp/interface/CharacterOptions";
import {useMemo} from "react";
import KidAltriniIcon from "images/character-app/kid-altrini.png";
import MissAltriniIcon from "images/character-app/miss-altrini.png";
import AltriniGirlIcon from "images/character-app/altrini-girl.png";
import CaptainAltriniIcon from "images/character-app/captain-altrini.png";
import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function CharacterTab() {
  const options = useMemo(() => {
    return [
      {
        value: 'KidAltrini',
        title: 'Kid Altrini',
        icon: KidAltriniIcon,
      },
      {
        value: 'MissAltrini',
        title: 'Miss Altrini',
        icon: MissAltriniIcon,
      },
      {
        value: 'AltriniGirl',
        title: 'Altrini Girl',
        icon: AltriniGirlIcon,
      },
      {
        value: 'CaptainAltrini',
        title: 'Captain Altrini',
        icon: CaptainAltriniIcon,
      },
    ];
  }, []);

  return <CharacterTabsPanel value={'character'}>
    <CharacterOptions name={'character'} options={options}/>
  </CharacterTabsPanel>
}
