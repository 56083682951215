import "styles/components/character-app/_color-picker.scss";
import useBEM from "hooks/useBEM";
import {useCallback, useEffect, useRef, useState} from "react";
import {Slider as CoreSlider} from "@mantine/core";
import IconButton from "components/interface/IconButton";
import {hexToHsb, hsbToHex} from "helpers/colorConverters";
import ScrollArea from "components/interface/ScrollArea";
import BadgeIcon from "icons/MetallicIcon";

function Swatch({swatch = {}, selectedColor, onChange}) {
  const classes = useBEM('color-picker');
  const handleClick = useCallback(() => {
    onChange && onChange(swatch.color);
  }, [swatch, onChange]);

  return <IconButton className={classes('swatch', {
    'active': selectedColor === swatch.color,
  })} onClick={handleClick}>
    {swatch.icon ? <img src={swatch.icon} alt={swatch.title} width={100} height={100} draggable={false}/> :
      <svg width={100} height={100} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={50} cy={50} r={50} fill={swatch.color}/>
      </svg>}
  </IconButton>
}

function BrightnessSlider({color, brightness, onChange}) {
  const sliderRef = useRef();
  const classes = useBEM('slider');
  const handleChange = useCallback((value) => {
    onChange && onChange(value);
  }, [onChange]);

  useEffect(() => {
    const slider = sliderRef?.current;
    if (slider) {
      const track = slider.querySelector('.slider__track');
      if (track && color.color) {
        const minHsb = hexToHsb(color.color);
        const maxHsb = hexToHsb(color.color);
        minHsb.b = 20;
        maxHsb.b = 100;
        track.style.backgroundImage = `linear-gradient(90deg, ${hsbToHex(minHsb)}, ${hsbToHex(maxHsb)})`;
      }

      const thumb = slider.querySelector('.slider__thumb');
      if (thumb && color.color) {
        const hsb = hexToHsb(color.color);
        hsb.b = brightness;
        thumb.style.backgroundColor = hsbToHex(hsb);
      }
    }
  }, [color, brightness]);

  return <CoreSlider ref={sliderRef} classNames={{
    root: classes(),
    thumb: classes('thumb'),
    trackContainer: classes('track-container'),
    track: classes('track'),
    label: classes('label'),
  }} onChange={handleChange} value={brightness} min={20} max={100}/>
}

export default function ColorPicker({colors = [], color = {}, onChange, allowMetallic = true, allowSwatches = true, allowSlider = true}) {
  const classes = useBEM('color-picker');
  const [selectedColor, setSelectedColor] = useState();
  const [brightness, setBrightness] = useState();
  const [metallic, setMetallic] = useState(!!color?.metallic);

  useEffect(() => {
    if (color.color !== selectedColor && colors.filter(swatch => swatch.color === color.color).length > 0) {
      setSelectedColor(color.color);
      setMetallic(!!color?.metallic);
    }

    if (color.color) {
      const hsb = hexToHsb(color.color);
      setBrightness(hsb.b);
    }

    if (!allowMetallic) {
      setMetallic(false);
    }
    else {
      setMetallic(!!color.metallic);
    }
  }, [colors, color, selectedColor, allowMetallic, setBrightness, setMetallic, onChange]);

  const handleSwatchClick = useCallback((newColor) => {
    setSelectedColor(newColor);
    onChange && onChange({
      ...color,
      color: newColor,
      metallic: metallic ? 1 : 0,
    });
  }, [color, metallic, setSelectedColor, onChange]);

  const handleBrightnessChange = useCallback((brightness) => {
    setBrightness(brightness);
    if (selectedColor) {
      const hsb = hexToHsb(selectedColor);
      hsb.b = brightness;
      onChange && onChange({
        ...color,
        color: hsbToHex(hsb),
        metallic: metallic ? 1 : 0,
      });
    }
  }, [color, metallic, onChange, selectedColor]);

  const toggleMetallic = useCallback(() => {
    setMetallic(!metallic);
    onChange && onChange({
      ...color,
      metallic: metallic ? 0 : 1,
    });
  }, [color, metallic, onChange]);

  return <div className={classes({
    metallic
  })}>
    {allowSwatches && <ScrollArea scrollbars={'y'} type={'auto'}>
      <div className={classes('swatches')}>
        {colors.map((swatch) => <Swatch key={swatch.color} swatch={swatch} selectedColor={selectedColor}
                                        onChange={handleSwatchClick}/>)}
      </div>
    </ScrollArea>}
    {allowSlider && <div className={classes('slider')}>
      {selectedColor && <>
        {typeof brightness !== 'undefined'  && <BrightnessSlider color={color} brightness={brightness} onChange={handleBrightnessChange}/>}
        {allowMetallic && <IconButton className={classes('metallic')} onClick={toggleMetallic}><BadgeIcon active={metallic}/></IconButton>}
      </>}
    </div>}
  </div>
}
