import {useFormBEM} from "hooks/useBEM";
import {TextInput as CoreTextInput} from "@mantine/core";
import {useCallback} from "react";

export default function TextInput({pattern, onChange, ...params}) {
  const classNames = useFormBEM('text-input');

  const handleChange = useCallback((e) => {
    if (pattern) {
      const re = new RegExp(pattern);
      const chars = e.currentTarget.value.split('').map(char => re.test(char) ? char : '');
      e.currentTarget.value = chars.join('');
    }
    onChange && onChange(e);
  }, [pattern, onChange]);

  return <CoreTextInput classNames={{
    ...classNames,
  }} inputWrapperOrder={['label', 'input', 'error', 'description']} onChange={handleChange} {...params}/>
}
