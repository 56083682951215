import CharacterApp from "./components/CharacterApp/CharacterApp";
import {BrowserRouter, Routes, Route, useParams} from "react-router-dom";
import {CharacterTabsProvider} from "contexts/CharacterTabs";
import {PUBLIC_URL} from "helpers/app";
import {useMemo} from "react";

function CharacterTabController() {
  const {tab, subTab} = useParams();
  const tabValue = useMemo(() => {
    const tabValue = [];
    if (tab && tab !== 'home') {
      tabValue.push(tab);
      if (subTab) {
        tabValue.push(subTab);
      }
    }
    return tabValue.map(item => item.replace('-', '_'));
  }, [tab, subTab]);

  return <CharacterTabsProvider value={tabValue.length > 0 ? tabValue.join('__') : null}>
    <CharacterApp/>
  </CharacterTabsProvider>
}

function App() {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route path="/tabs/:tab" element={<CharacterTabController/>} />
        <Route path="/tabs/:tab/:subTab" element={<CharacterTabController/>} />
        <Route path="*" element={<CharacterTabController/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
