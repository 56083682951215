import "styles/components/character-app/_powers.scss";
import useBEM from "hooks/useBEM";
import {useCallback, useMemo} from "react";
import PowerSlider from "components/CharacterApp/interface/PowerSlider";
import {useCharacterParams} from "contexts/CharacterParams";
import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";
import BottleIcon from "icons/BottleIcon";
import Button from "components/interface/Button";
import ResetIcon from "icons/ResetIcon";

function PowerItem({power, value = 0, max = 4, onChange}) {
  const {parameters} = useCharacterParams();
  const flavour = parameters?.flavour || 'strawberry';
  return <>
    <div className={'powers__item-label'} key={power.id + '_title'}>{power.title}:</div>
    <div className={'powers__item-slider'} key={power.id + '_slider'}><PowerSlider key={power.id} value={value} max={max} flavour={flavour} onChange={onChange}/></div>
  </>
}

function Powers({max = 6}) {
  const classes = useBEM('powers');
  const items = useMemo(() => {
    return [
      {
        id: 'strength',
        title: 'Strength',
      },
      {
        id: 'flight',
        title: 'Flight',
      },
      {
        id: 'force_field',
        title: 'Force Field',
      },
      {
        id: 'electro_blast',
        title: 'Electro Blast',
      },
      {
        id: 'invisibility',
        title: 'Invisibility',
      },
    ];
  }, []);
  const {parameters, setParameter} = useCharacterParams();
  const values = useMemo(() => parameters?.powers || {}, [parameters.powers]);
  const used = useMemo(() => {
    return Object.values(values).reduce((partialSum, value) => partialSum + value, 0);
  }, [values]);

  const left = useMemo(() => {
    return max - used;
  }, [max, used]);

  const handleChange = useCallback((id) => {
    return (value) => {
      setParameter('powers', {
        ...values,
        [id]: value,
      });
    };
  }, [values, setParameter]);

  const handleReset = useCallback(() => {
    setParameter('powers', {});
  }, [setParameter]);

  return <div className={classes()}>
    <div className={classes('header')}>
      <div className={classes('header-bottles')}>
        <span>{left} bottles left</span>
        {[...Array(left)].map((e, i) => <BottleIcon width={10} full={true} key={i}/> )}
      </div>
      <Button leftSection={<ResetIcon/>} variant={'transparent'} onClick={handleReset}>Reset</Button>
    </div>
    <div className={classes('items')}>
      {items.map(power => {
        const value = values[power.id] || 0;
        const itemMax = value + Math.max((max - used), 0);
        return <PowerItem key={power.id} power={power} value={value} max={itemMax} onChange={handleChange(power.id)}/>
      })}
    </div>
  </div>
}

export default function PowersTab() {
  const classes = useBEM('character-tabs');

  return <CharacterTabsPanel value={'powers'}>
    <div className={classes('panel-inner')}>
      <h4 className={classes('panel-title')}>Choose Your Super Powers</h4>
      <Powers/>
    </div>
  </CharacterTabsPanel>
}
