/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/hair/hair02.glb
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "hooks/useCharacterMaterials";
import useCharacterMorphs from "hooks/useCharacterMorphs";
import {PUBLIC_URL} from "helpers/app";

export function Hair02(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/hair/hair02.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);

  return (
    <group {...props} dispose={null}>
      <mesh name="Hair" geometry={nodes.Hair.geometry} material={characterMaterials.Hair} morphTargetDictionary={nodes.Hair.morphTargetDictionary} morphTargetInfluences={nodes.Hair.morphTargetInfluences} position={[0, 1.289, -0.098]} rotation={[0.047, 0, 0]} scale={0.922} />
    </group>
  )
}

useGLTF.preload(PUBLIC_URL + '/assets/models/hair/hair02.glb')
