import useBEM from "hooks/useBEM";
import {Tabs as CoreTabs} from "@mantine/core";

export function Tabs(props) {
  const classes = useBEM('tabs');

  return <CoreTabs classNames={{
    root: classes(),
    list: classes('list'),
    tab: classes('tab'),
    panel: classes('panel'),
  }} {...props}/>
}

export const Tab = CoreTabs.Tab;
export const TabsList = CoreTabs.List;
export const TabsPanel = CoreTabs.Panel;
