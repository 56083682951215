import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.mode = 'cors';
axios.defaults.headers = {
  'Accept': 'application/vnd.api+json',
  'Content-Type': 'application/json',
};

const abortController = new AbortController();
const client = axios.create({
  'baseURL': process.env.REACT_APP_API_BASE_URL,
  'mode': 'cors',
  'headers': {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/json',
  },
  'signal': abortController.signal,
});

const getClient = () => {
  return client;
}

const abort = () => {
  abortController.abort();
}

const apiService = {
  getClient,
  abort,
}

export default apiService
