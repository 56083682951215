export default function MaskIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.6305 21C19.928 21 13.37 22.1405 10.8038 27.9856C9.94844 29.9815 9.80584 32.8328 10.2335 35.2564C11.374 41.5292 18.5023 43.24 23.6346 43.0974C25.6305 43.0974 27.6264 42.9549 29.7648 42.2421C31.6182 41.5292 33.4715 40.5313 35.0397 39.3908C36.3228 40.3887 37.7484 41.2441 39.3167 41.6718C40.8849 42.2421 42.168 42.8123 43.8787 42.9549C45.5895 43.0974 47.4428 42.9549 49.2961 42.9549C51.0069 42.9549 53.0028 42.3846 54.7136 41.8144C57.2797 40.8164 59.133 38.2503 59.7033 35.5415C60.2736 33.118 59.9885 30.2667 59.1331 27.9856C57.9926 24.9918 55.9966 23.4236 53.0028 22.4256C50.0089 21.4277 48.0131 21 44.8767 21M45.4469 28.5559C46.73 28.6985 48.4407 28.6985 49.4387 29.6964L49.5813 29.839C49.8664 30.2667 50.1515 30.6944 50.2941 31.2646C50.4367 31.6923 50.5792 32.2626 50.4366 32.8328C50.0089 34.6862 47.8705 34.6862 46.4449 34.6862C45.1618 34.6862 44.0213 34.6862 42.8808 34.1159C41.4551 33.5456 40.5998 32.12 39.8869 30.8369C38.6039 28.5559 44.4489 28.4133 45.4469 28.5559ZM19.7853 31.2646C19.9279 30.6944 20.213 30.2667 20.4982 29.839L20.6408 29.6964C21.6387 28.6985 23.492 28.841 24.7751 28.6985C25.773 28.6985 31.4756 28.6985 30.1925 30.9795C29.4797 32.2626 28.4818 33.5456 27.1987 34.2585C26.0582 34.6862 24.7751 34.8287 23.6346 34.8287C22.209 34.8287 20.0705 34.6862 19.6428 32.9754C19.6428 32.2626 19.6428 31.6923 19.7853 31.2646Z"
      fill="#696969"/>
    <path
      d="M25.6305 20C19.928 20 13.37 21.1405 10.8038 26.9856C9.94844 28.9815 9.80584 31.8328 10.2335 34.2564C11.374 40.5292 18.5023 42.24 23.6346 42.0974C25.6305 42.0974 27.6264 41.9549 29.7648 41.2421C31.6182 40.5292 33.4715 39.5313 35.0397 38.3908C36.3228 39.3887 37.7484 40.2441 39.3167 40.6718C40.8849 41.2421 42.168 41.8123 43.8787 41.9549C45.5895 42.0974 47.4428 41.9549 49.2961 41.9549C51.0069 41.9549 53.0028 41.3846 54.7136 40.8144C57.2797 39.8164 59.133 37.2503 59.7033 34.5415C60.2736 32.118 59.9885 29.2667 59.1331 26.9856C57.9926 23.9918 55.9966 22.4236 53.0028 21.4256C50.0089 20.4277 48.0131 20 44.8767 20M45.4469 27.5559C46.73 27.6985 48.4407 27.6985 49.4387 28.6964L49.5813 28.839C49.8664 29.2667 50.1515 29.6944 50.2941 30.2646C50.4367 30.6923 50.5792 31.2626 50.4366 31.8328C50.0089 33.6862 47.8705 33.6862 46.4449 33.6862C45.1618 33.6862 44.0213 33.6862 42.8808 33.1159C41.4551 32.5456 40.5998 31.12 39.8869 29.8369C38.6039 27.5559 44.4489 27.4133 45.4469 27.5559ZM19.7853 30.2646C19.9279 29.6944 20.213 29.2667 20.4982 28.839L20.6408 28.6964C21.6387 27.6985 23.492 27.841 24.7751 27.6985C25.773 27.6985 31.4756 27.6985 30.1925 29.9795C29.4797 31.2626 28.4818 32.5456 27.1987 33.2585C26.0582 33.6862 24.7751 33.8287 23.6346 33.8287C22.209 33.8287 20.0705 33.6862 19.6428 31.9754C19.6428 31.2626 19.6428 30.6923 19.7853 30.2646Z"
      fill="white"/>
  </svg>
}
