import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const CharacterTabsContext = createContext({});

export const CharacterTabsProvider = ({value, children}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(value ?? 'character');

  useEffect(() => {
    if (value) {
      setActiveTab(value);
    }
  }, [value, setActiveTab]);

  const handleActiveTab = useCallback((value) => {
    const url = value.replace('__', '/').replace('_', '-');
    setActiveTab(value);
    navigate(`/tabs/${url}`);
  }, [setActiveTab, navigate]);

  return (
    <CharacterTabsContext.Provider
      value={{
        activeTab,
        setActiveTab: handleActiveTab,
      }}
    >
      {children}
    </CharacterTabsContext.Provider>
  );
};

export const useCharacterTabs = () => {
  return useContext(CharacterTabsContext);
};
