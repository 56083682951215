/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/hair/hair03.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "hooks/useCharacterMaterials";
import useCharacterMorphs from "hooks/useCharacterMorphs";
import {PUBLIC_URL} from "helpers/app";

export function Hair03(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/hair/hair03.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Hair03.geometry} material={characterMaterials.Hair} position={[0, 1.289, -0.098]} rotation={[0.047, 0, 0]} scale={0.922} />
    </group>
  )
}

useGLTF.preload(PUBLIC_URL + '/assets/models/hair/hair03.glb')
