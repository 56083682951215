import {useMemo} from "react";
import CharacterOptions from "components/CharacterApp/interface/CharacterOptions";
import NoAccessoriesIcon from "images/character-app/no-accessories.png";
import HeadphonesIcon from "images/character-app/headphones.png";
import EarringsIcon from "images/character-app/earrings.png";
import EarringLeftIcon from "images/character-app/earring-left.png";
import EarringRightIcon from "images/character-app/earring-right.png";
import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function AccessoryTab() {
  const options = useMemo(() => {
    return [
      {
        value: false,
        title: 'No accessory',
        icon: NoAccessoriesIcon,
      },
      {
        value: 'headphones',
        title: 'Headphones',
        icon: HeadphonesIcon,
      },
      {
        value: 'earrings',
        title: 'Earrings',
        icon: EarringsIcon,
      },
      {
        value: 'earring_right',
        title: 'Right earring',
        icon: EarringRightIcon,
      },
      {
        value: 'earring_left',
        title: 'Left earring',
        icon: EarringLeftIcon,
      },
    ];
  }, []);

  return <CharacterTabsPanel value={'accessory'}>
    <CharacterOptions name={'accessory'} options={options}/>
  </CharacterTabsPanel>
}
