import {useCharacterExport} from "contexts/CharacterExport";
import useBEM from "hooks/useBEM";
import CharacterTabsPanel, {CharacterTabsPanelHeader} from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";
import RenderButton from "components/CharacterApp/interface/RenderButton";
import NameTag from "components/CharacterApp/interface/NameTag";
import ARButton from "components/CharacterApp/interface/ARButton";
import WallButton from "components/CharacterApp/interface/WallButton";

export default function SaveTab() {
  const classes = useBEM('character-tabs');
  const {scene} = useCharacterExport();

  return <CharacterTabsPanel value={'save'}>
    <CharacterTabsPanelHeader>
      <NameTag/>
    </CharacterTabsPanelHeader>

    <div className={classes('panel-inner')}>
      <div className={classes('actions')}>
        <ARButton scene={scene}/>
        <RenderButton/>
        <WallButton/>
      </div>
    </div>
  </CharacterTabsPanel>
}
