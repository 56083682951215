import ColorPicker from "components/CharacterApp/interface/ColorPicker";
import {useCallback, useMemo} from "react";
import {useCharacterParams} from "contexts/CharacterParams";
import CharacterTabsPanel, {CharacterTabsPanelFooter} from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function HairColorTab() {
  const { colors, setColor } = useCharacterParams();
  const availableColors = useMemo(() => {
    return [
      {
        color: '#F0F0F3',
      },
      {
        color: '#F5DAA6',
      },
      {
        color: '#E7BA63',
      },
      {
        color: '#CBBBA5',
      },
      {
        color: '#EB8F05',
      },
      {
        color: '#B5772A',
      },
      {
        color: '#844815',
      },
      {
        color: '#A9330E',
      },
      {
        color: '#63340C',
      },
      {
        color: '#231202',
      },
      {
        color: '#BD9FC6',
      },
      {
        color: '#F29F8C',
      },
      {
        color: '#F632A8',
      },
      {
        color: '#47C2B6',
      },
      {
        color: '#37E521',
      },
      {
        color: '#920261',
      },
      {
        color: '#312E8B',
      },
      {
        color: '#B1D7ED',
      },
    ];
  }, []);

  const handleChange = useCallback((color) => {
    setColor('hair', color);
  }, [setColor]);

  return <CharacterTabsPanel value={'hair_color'}>
    <ColorPicker colors={availableColors} color={colors.hair} onChange={handleChange} allowMetallic={false} allowSlider={false}/>
    <CharacterTabsPanelFooter>
      <ColorPicker colors={availableColors} color={colors.hair} onChange={handleChange} allowMetallic={false} allowSwatches={false}/>
    </CharacterTabsPanelFooter>
  </CharacterTabsPanel>
}
