export default function MetallicIcon({size = 48}) {
  return <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" fill="#D9D9D9"/>
    <rect width="48" height="48" fill="url(#paint0_linear_1348_10440)" fillOpacity="0.6"/>
    <defs>
      <linearGradient id="paint0_linear_1348_10440" x1="70" y1="-18" x2="-22" y2="74" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="0.5" stopColor="white"/>
        <stop offset="1"/>
      </linearGradient>
    </defs>
  </svg>

}
