import {} from '@google/model-viewer';
import Button from "components/interface/Button";
import {useCallback, useEffect, useState} from "react";
import ARIcon from "icons/ARIcon";
import {exportHero} from "helpers/app";

const ARButton = ({scene}) => {
  const [viewer, setViewer] = useState();
  const [isLoading, setLoading] = useState(false);

  const handleExport = useCallback(async () => {
    if (!scene || !viewer) return;
    setLoading(true);

    try {
      const gltf = await exportHero(scene, 'gltf');
      viewer.src = URL.createObjectURL(gltf);
    }
    catch (e) {}

    try {
      const usdz = await exportHero(scene, 'usdz');
      viewer.iosSrc = URL.createObjectURL(usdz);
    }
    catch (e) {}

    setLoading(false);
  }, [setLoading, scene, viewer]);

  const handleAr = useCallback(() => {
    try {
      if (viewer) {
        viewer.activateAR();
      }
    }
    catch (e) {
      console.error(e);
    }
  }, [viewer]);

  useEffect(() => {
    if (scene && viewer && handleExport) {
      handleExport().then(() => {
        console.log('Scene ready for AR.');
      });
    }
  }, [scene, viewer, handleExport]);

  return <>
    <Button disabled={isLoading} onClick={handleAr} variant={'square'} fullWidth leftSection={<ARIcon/>}>
      {isLoading ? 'Preparing for AR...' : 'View in AR'}
      <model-viewer class="export-viewer" ref={setViewer} ar
                    ar-modes="quick-look webxr" reveal="manual"/>
    </Button>
  </>
}

export default ARButton;
