export default function TextIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.1701 49.4366C49.9218 49.4366 49.6735 49.2455 49.6735 48.9779V12.3962C49.6735 12.1669 49.8804 11.9375 50.1701 11.9375C50.4597 11.9375 50.6666 12.1286 50.6666 12.3962V48.9779C50.6666 49.2073 50.4597 49.4366 50.1701 49.4366Z"
      fill="#696969"/>
    <path
      d="M47.0257 17.2007V27.019C47.0257 28.147 46.8188 28.9408 46.4464 29.3586C46.0739 29.7764 45.5774 29.9853 44.9567 29.9853C44.336 29.9853 43.8808 29.7764 43.5084 29.3586C43.136 28.9408 42.9291 28.1052 42.9291 27.019V22.5067C42.9291 21.8383 42.3911 21.2951 41.729 21.2951H37.0531C36.391 21.2951 35.8531 21.8383 35.8531 22.5067V42.3521C35.8531 42.6864 35.9772 43.0206 36.2255 43.2713C36.4738 43.522 36.8048 43.6473 37.1359 43.6473H40.0738C41.1911 43.6473 41.9773 43.8562 42.4325 44.2322C42.8463 44.6083 43.0532 45.1096 43.0532 45.7363C43.0532 46.363 42.8463 46.8226 42.4325 47.1986C42.0187 47.5746 41.2325 47.7835 40.0738 47.7835H27.5771C26.4598 47.7835 25.6736 47.5746 25.2598 47.1986C24.846 46.8226 24.6391 46.3212 24.6391 45.6945C24.6391 45.0678 24.846 44.6083 25.2598 44.2322C25.6736 43.8562 26.4598 43.6473 27.5771 43.6473H30.4737C30.8047 43.6473 31.1358 43.522 31.384 43.2713C31.6323 43.0206 31.7565 42.6864 31.7565 42.3521V22.5067C31.7565 21.8383 31.2185 21.2951 30.5564 21.2951H25.9633C25.3012 21.2951 24.7632 21.8383 24.7632 22.5067V27.019C24.7632 28.147 24.5563 28.9408 24.1839 29.4004C23.8115 29.8182 23.3149 30.0271 22.6942 30.0271C22.0735 30.0271 21.6184 29.8182 21.2459 29.4004C20.8735 28.9826 20.6666 28.147 20.6666 27.019V17.1589H46.9843C46.9843 17.1589 47.0257 17.2007 47.0257 17.2425V17.2007Z"
      fill="#696969"/>
    <path
      d="M50.1972 48.4991C49.9628 48.4991 49.7285 48.308 49.7285 48.0404V11.4587C49.7285 11.2294 49.9238 11 50.1972 11C50.4706 11 50.6659 11.1911 50.6659 11.4587V48.0404C50.6659 48.2698 50.4706 48.4991 50.1972 48.4991Z"
      fill="white"/>
    <path
      d="M47.0257 16.2632V26.0815C47.0257 27.2095 46.8188 28.0033 46.4464 28.4211C46.0739 28.8389 45.5774 29.0478 44.9567 29.0478C44.336 29.0478 43.8808 28.8389 43.5084 28.4211C43.136 28.0033 42.9291 27.1677 42.9291 26.0815V21.5692C42.9291 20.9008 42.3911 20.3576 41.729 20.3576H37.0531C36.391 20.3576 35.8531 20.9008 35.8531 21.5692V41.4146C35.8531 41.7489 35.9772 42.0831 36.2255 42.3338C36.4738 42.5845 36.8048 42.7098 37.1359 42.7098H40.0738C41.1911 42.7098 41.9773 42.9187 42.4325 43.2947C42.8463 43.6708 43.0532 44.1721 43.0532 44.7988C43.0532 45.4255 42.8463 45.8851 42.4325 46.2611C42.0187 46.6371 41.2325 46.846 40.0738 46.846H27.5771C26.4598 46.846 25.6736 46.6371 25.2598 46.2611C24.846 45.8851 24.6391 45.3837 24.6391 44.757C24.6391 44.1303 24.846 43.6708 25.2598 43.2947C25.6736 42.9187 26.4598 42.7098 27.5771 42.7098H30.4737C30.8047 42.7098 31.1358 42.5845 31.384 42.3338C31.6323 42.0831 31.7565 41.7489 31.7565 41.4146V21.5692C31.7565 20.9008 31.2185 20.3576 30.5564 20.3576H25.9633C25.3012 20.3576 24.7632 20.9008 24.7632 21.5692V26.0815C24.7632 27.2095 24.5563 28.0033 24.1839 28.4629C23.8115 28.8807 23.3149 29.0896 22.6942 29.0896C22.0735 29.0896 21.6184 28.8807 21.2459 28.4629C20.8735 28.0451 20.6666 27.2095 20.6666 26.0815V16.2214H46.9843C46.9843 16.2214 47.0257 16.2632 47.0257 16.305V16.2632Z"
      fill="white"/>
  </svg>
}
