export default function HeroIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.4348 32.243L22.0221 27.1617C21.2553 26.0305 21.0771 24.4934 21.5469 23.2114C15.4829 31.4774 14.6567 46.3212 15.5153 47.7772C16.6816 49.8248 17.7022 45.1089 24.5276 47.0463C25.3808 47.2899 26.3527 47.4755 27.4867 47.6612L27.9511 33.7164C26.9845 33.7164 26.0395 33.1479 25.4456 32.243H25.4348Z"
      fill="#696969"/>
    <path
      d="M42.931 23.2295H40.1933L40.2365 32.1915L41.4515 48.0157V48.2941C45.6687 48.1085 48.6656 47.4066 48.1418 46.0086C46.9701 42.6501 44.3188 30.0917 42.9256 23.2295H42.931Z"
      fill="#696969"/>
    <path
      d="M49.1889 17.9103H34.199C26.5097 17.9103 22.989 23.4209 22.8162 23.6529C22.3356 24.4012 22.3842 25.3815 22.8594 26.0776L26.3369 31.304C26.7257 31.913 27.3358 32.1915 27.946 32.1915C28.3348 32.1915 28.7722 32.0523 29.1178 31.7738C29.2042 31.681 29.3338 31.5882 29.4202 31.4954V32.9862L28.2052 48.8568C28.1188 50.1619 28.9882 51.3278 30.2463 51.4264C31.4613 51.5192 32.5467 50.5853 32.6385 49.2338L33.8102 33.9723H34.6796L35.8514 49.2338C35.9378 50.4925 36.8935 51.4264 38.0653 51.4264H38.2381C39.453 51.3336 40.3656 50.1677 40.2792 48.8568L39.0643 32.9862L38.9779 22.1099H49.2321C50.3175 22.1099 51.2301 21.1296 51.2301 19.9637C51.1869 18.8906 50.3175 17.9103 49.1889 17.9103ZM29.4634 28.6473L27.0712 25.0045C27.6382 24.4418 28.4158 23.7921 29.5066 23.2759L29.4634 28.6415V28.6473Z"
      fill="#696969"/>
    <path
      d="M34.1994 17.1682C36.6478 17.1682 38.6326 15.0361 38.6326 12.4059C38.6326 9.77572 36.6478 7.64355 34.1994 7.64355C31.7509 7.64355 29.7661 9.77572 29.7661 12.4059C29.7661 15.0361 31.7509 17.1682 34.1994 17.1682Z"
      fill="#696969"/>
    <path
      d="M25.4348 31.3155L22.0221 26.2342C21.2553 25.1031 21.0771 23.5659 21.5469 22.2839C15.4829 30.5499 14.6567 45.3937 15.5153 46.8497C16.6816 48.8973 17.7022 44.1814 24.5276 46.1188C25.3808 46.3624 26.3527 46.5481 27.4867 46.7337L27.9511 32.7889C26.9845 32.7889 26.0395 32.2205 25.4456 31.3155H25.4348Z"
      fill="white"/>
    <path
      d="M42.931 22.3018H40.1933L40.2365 31.2638L41.4515 47.0879V47.3664C45.6687 47.1807 48.6656 46.4789 48.1418 45.0809C46.9701 41.7223 44.3188 29.1639 42.9256 22.3018H42.931Z"
      fill="white"/>
    <path
      d="M49.1889 16.9825H34.199C26.5097 16.9825 22.989 22.4932 22.8162 22.7252C22.3356 23.4735 22.3842 24.4538 22.8594 25.1499L26.3369 30.3762C26.7257 30.9853 27.3358 31.2637 27.946 31.2637C28.3348 31.2637 28.7722 31.1245 29.1178 30.8461C29.2042 30.7533 29.3338 30.6605 29.4202 30.5677V32.0584L28.2052 47.929C28.1188 49.2342 28.9882 50.4001 30.2463 50.4987C31.4613 50.5915 32.5467 49.6576 32.6385 48.3061L33.8102 33.0445H34.6796L35.8514 48.3061C35.9378 49.5648 36.8935 50.4987 38.0653 50.4987H38.2381C39.453 50.4059 40.3656 49.24 40.2792 47.929L39.0643 32.0584L38.9779 21.1822H49.2321C50.3175 21.1822 51.2301 20.2019 51.2301 19.036C51.1869 17.9629 50.3175 16.9825 49.1889 16.9825ZM29.4634 27.7195L27.0712 24.0767C27.6382 23.5141 28.4158 22.8644 29.5066 22.3481L29.4634 27.7137V27.7195Z"
      fill="white"/>
    <path
      d="M34.1994 16.2405C36.6478 16.2405 38.6326 14.1083 38.6326 11.4782C38.6326 8.84799 36.6478 6.71582 34.1994 6.71582C31.7509 6.71582 29.7661 8.84799 29.7661 11.4782C29.7661 14.1083 31.7509 16.2405 34.1994 16.2405Z"
      fill="white"/>
  </svg>
}
