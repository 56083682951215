export default function PowersIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.5239 14.6876C41.9276 13.7028 40.7175 12.8527 39.928 13.5666L21.97 29.8015C21.3952 30.3212 21.6043 31.2689 22.3444 31.4984L36.8757 36.0042C37.5099 36.2009 37.7798 36.9497 37.417 37.5058L29.6733 49.3738C29.0253 50.3669 30.3382 51.4618 31.1988 50.646L49.2807 33.5045C49.7979 33.0143 49.6509 32.1558 49.0001 31.8655L37.6121 26.7866C37.1186 26.5665 36.8892 25.9941 37.0942 25.4941L41.5239 14.6876Z"
      fill="#696969"/>
    <path
      d="M41.5239 13.6876C41.9276 12.7028 40.7175 11.8527 39.928 12.5666L21.97 28.8015C21.3952 29.3212 21.6043 30.2689 22.3444 30.4984L36.8757 35.0042C37.5099 35.2009 37.7798 35.9497 37.417 36.5058L29.6733 48.3738C29.0253 49.3669 30.3382 50.4618 31.1988 49.646L49.2807 32.5045C49.7979 32.0143 49.6509 31.1558 49.0001 30.8655L37.6121 25.7866C37.1186 25.5665 36.8892 24.9941 37.0942 24.4941L41.5239 13.6876Z"
      fill="white"/>
  </svg>

}
