export default function HeadphonesIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.2553 28.3802C50.3463 28.5622 50.0733 28.7442 49.8913 28.7442C49.6183 28.6532 49.2543 28.6532 48.9813 28.5622C48.5263 28.4712 48.0713 28.9262 48.0713 29.3812V44.0323C48.0713 44.4873 48.5263 44.9423 48.9813 44.8513C49.1633 44.8513 49.4363 44.7603 49.8003 44.6693C50.6193 44.3963 50.4373 44.4873 51.0743 44.2143C51.4383 44.0323 51.7113 43.8503 51.8023 43.8503C54.4414 42.4853 56.2614 39.7553 56.2614 36.6612C56.2614 34.5682 55.4424 32.5662 54.0774 31.1102C54.0774 31.0192 53.9864 31.0192 53.9864 30.9282C53.4404 20.9181 45.0683 12.91 34.8762 12.91C24.6841 12.91 16.312 21.0091 15.675 31.0192C15.675 31.1102 15.584 31.1102 15.584 31.2012C14.219 32.6572 13.4 34.5682 13.4 36.7522C13.4 40.9383 16.5851 44.3963 20.6801 44.9423C21.1351 45.0333 21.5901 44.5783 21.5901 44.1233V29.4722C21.5901 29.0172 21.1351 28.5622 20.6801 28.6532C20.4071 28.6532 20.0431 28.7442 19.7701 28.8352C19.5881 28.9262 19.4061 28.6532 19.4061 28.4712C21.2261 21.4641 27.4141 16.3681 34.8762 16.3681C42.3383 16.3681 48.5263 21.4641 50.2553 28.3802Z"
      fill="#696969"/>
    <path
      d="M50.2553 27.4701C50.3463 27.6521 50.0733 27.8341 49.8913 27.8341C49.6183 27.7431 49.2543 27.7431 48.9813 27.6521C48.5263 27.5611 48.0713 28.0161 48.0713 28.4711V43.1223C48.0713 43.5773 48.5263 44.0323 48.9813 43.9413C49.1633 43.9413 49.4363 43.8503 49.8003 43.7593C50.6193 43.4863 50.4373 43.5773 51.0743 43.3043C51.4383 43.1223 51.7113 42.9403 51.8023 42.9403C54.4414 41.5752 56.2614 38.8452 56.2614 35.7512C56.2614 33.6582 55.4424 31.6562 54.0774 30.2001C54.0774 30.1091 53.9864 30.1091 53.9864 30.0181C53.4404 20.0081 45.0683 12 34.8762 12C24.6841 12 16.312 20.0991 15.675 30.1091C15.675 30.2001 15.584 30.2001 15.584 30.2911C14.219 31.7472 13.4 33.6582 13.4 35.8422C13.4 40.0282 16.5851 43.4863 20.6801 44.0323C21.1351 44.1233 21.5901 43.6683 21.5901 43.2133V28.5621C21.5901 28.1071 21.1351 27.6521 20.6801 27.7431C20.4071 27.7431 20.0431 27.8341 19.7701 27.9251C19.5881 28.0161 19.4061 27.7431 19.4061 27.5611C21.2261 20.5541 27.4141 15.458 34.8762 15.458C42.3383 15.458 48.5263 20.5541 50.2553 27.4701Z"
      fill="white"/>
  </svg>
}
