export default function EyeIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35 26.9235C34.45 26.9235 33.9 27.1115 33.35 27.1115C34.2667 27.4876 35 28.4278 35 29.556C35 31.0602 33.7167 32.1884 32.25 32.1884C31.15 32.1884 30.2333 31.6243 29.6833 30.6842C29.5 31.2483 29.5 31.6243 29.5 32.1884C29.5 35.0089 31.8833 37.4534 35 37.4534C38.1167 37.4534 40.5 35.0089 40.5 32.1884C40.5 29.3679 38.1167 26.9235 35 26.9235ZM35 20C21.25 20 13 34.6667 13 34.6667C13 34.6667 21.25 43.6923 35 43.6923C48.75 43.6923 57 32.1884 57 32.1884C57 32.1884 48.75 20 35 20ZM35 40.0859C30.4167 40.0859 26.75 36.5132 26.75 32.1884C26.75 27.8636 30.4167 24.291 35 24.291C39.5833 24.291 43.25 27.8636 43.25 32.1884C43.25 36.5132 39.5833 40.0859 35 40.0859Z"
      fill="#696969"/>
    <path
      d="M35 25.9235C34.45 25.9235 33.9 26.1115 33.35 26.1115C34.2667 26.4876 35 27.4278 35 28.556C35 30.0602 33.7167 31.1884 32.25 31.1884C31.15 31.1884 30.2333 30.6243 29.6833 29.6842C29.5 30.2483 29.5 30.6243 29.5 31.1884C29.5 34.0089 31.8833 36.4534 35 36.4534C38.1167 36.4534 40.5 34.0089 40.5 31.1884C40.5 28.3679 38.1167 25.9235 35 25.9235ZM35 19C21.25 19 13 33.6667 13 33.6667C13 33.6667 21.25 42.6923 35 42.6923C48.75 42.6923 57 31.1884 57 31.1884C57 31.1884 48.75 19 35 19ZM35 39.0859C30.4167 39.0859 26.75 35.5132 26.75 31.1884C26.75 26.8636 30.4167 23.291 35 23.291C39.5833 23.291 43.25 26.8636 43.25 31.1884C43.25 35.5132 39.5833 39.0859 35 39.0859Z"
      fill="white"/>
  </svg>
}
