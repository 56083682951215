/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/accessories/headphones.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "../../../hooks/useCharacterMaterials";
import useCharacterMorphs from "../../../hooks/useCharacterMorphs";
import {PUBLIC_URL} from "helpers/app";

export function Headphones(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/accessories/headphones.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);
  
  return (
    <group {...props} dispose={null}>
      <group position={[0, 1.07, 0.009]}>
        <mesh geometry={nodes.Headphones_1.geometry} material={characterMaterials.SuitSecondary} />
        <mesh geometry={nodes.Headphones_2.geometry} material={characterMaterials.SuitSecondaryDark} />
        <mesh geometry={nodes.Headphones_3.geometry} material={characterMaterials.Accessory} />
        <mesh geometry={nodes.Antennas.geometry} material={characterMaterials.Accessory} position={[0, -0.024, -0.141]} />
      </group>
    </group>
  )
}

useGLTF.preload(PUBLIC_URL + '/assets/models/accessories/headphones.glb')
