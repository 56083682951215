import * as THREE from "three";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {USDZExporter} from "three/examples/jsm/exporters/USDZExporter";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";

export const PUBLIC_URL = process.env.PUBLIC_URL;
export const isStandalone = (navigator && navigator.standalone) ||
  matchMedia('(display-mode: standalone)').matches ||
  matchMedia('(display-mode: fullscreen)').matches;

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const matches = reader.result.match(/^data:([^;]+);base64,(.*)/);
      if (matches && matches.length > 2) {
        resolve({
          mimetype: matches[1],
          data: matches[2],
        });
      }
      else {
        reject('Can\'t parse data url.');
      }
    }
    reader.readAsDataURL(blob);
  });
}

export const renderHero = async (scene, background = false, width = 1080, height = 1080) => {
  return new Promise((resolve, reject) => {
    if (scene) {
      const textureLoader = new THREE.TextureLoader();
      // Init renderer.
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        preserveDrawingBuffer: true,
        premultipliedAlpha: false
      });
      const renderScene = new THREE.Scene();
      renderer.toneMapping = THREE.NoToneMapping;
      renderer.setSize(width, height);
      renderer.setPixelRatio(2);

      // Camera
      const camera = new THREE.PerspectiveCamera(55, 1, 1, 1000);
      camera.position.set(0, 0.45, 2);
      camera.rotation.set(Math.PI * 0.05, 0, 0);
      camera.up.set(0, 1, 0);

      const dispose = () => {
        const disposeChildren = (obj) => {
          if (Array.isArray(obj.children)) {
            obj.children.forEach(child => {
              disposeChildren(child);
            })
          }
          if (typeof obj.dispose === 'function') {
            obj.dispose();
          }
          if (typeof obj.geometry === 'object' && typeof obj.geometry.dispose === 'function') {
            obj.geometry.dispose();
          }
        };
        disposeChildren(renderScene);
        renderer.dispose();
        renderer.forceContextLoss();
      };

      const onError = () => {
        dispose();
        reject();
      };

      const onLoad = () => {
        // Set-up scene.
        const renderObject = scene.clone();
        renderScene.add(renderObject);
        const keyLight = new THREE.DirectionalLight();
        keyLight.position.set(2, 3, 3);
        keyLight.target.position.set(0, 1, 0);
        keyLight.intensity = 1;
        renderScene.add(keyLight);

        // Render.
        renderer.render(renderScene, camera);
        renderer.domElement.toBlob((blob) => {
          dispose();
          resolve(blob);
        });
      }

      // Load HDRi
      const envTexture = new RGBELoader().setPath(PUBLIC_URL + '/assets/hdri/').load('environment.hdr', () => {
        envTexture.mapping = THREE.EquirectangularReflectionMapping;
        renderScene.environment = envTexture;
        renderScene.environmentIntensity = 0.5;
        renderScene.environmentRotation.set(0, -Math.PI / 4, 0);

        // Load background image
        if (background) {
          const bgTexture = textureLoader.load(PUBLIC_URL + '/assets/background/' + background + '.png', () => {
            bgTexture.colorSpace = 'srgb';
            renderScene.background = bgTexture;
            onLoad();
          }, () => {}, onError);
        }
        else {
          onLoad();
        }
      }, () => {}, () => onError);
    }
    else {
      reject();
    }
  });
};

export const exportHero = async (scene, format = 'gltf', ) => {
  return new Promise((resolve, reject) => {
    let exporter;
    let options = {};
    let mimetype;

    if (!scene) {
      reject('Missing export object.');
    }

    switch (format) {
      default:
        reject('Unknown format.');
        break;
      case 'gltf':
        exporter = new GLTFExporter();
        options = {
          binary: true,
        };
        mimetype = 'model/gltf-binary';
        break;
      case 'usdz':
        exporter = new USDZExporter();
        mimetype = 'model/vnd.usdz+zip';
        break;
    }

    exporter.parseAsync(scene, options).then((data) => {
      const blob = new Blob([data], { type: mimetype });
      resolve(blob);
    }).catch(e => {
      console.error(e);
      reject('Error exporting scene.');
    });
  });
}

export const easeInOutSine = (min, max, time, fps, onFrame) => {
  let t = 0;
  time = time || 0;
  const frameTime = 1000 / fps;
  const dt = frameTime / time;

  return (() => {
    const ease = (t) => {
      return min - (max - min) * (Math.cos(Math.PI * t) - 1) / 2;
    }
    const animate = () => {
      const delta = ease(t + dt) - ease(t);
      onFrame && onFrame(delta);
      t += dt;
      if (t >= 2) {
        t = t - 2;
      }
    };
    return setInterval(animate, frameTime);
  })();
}
