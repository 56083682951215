import useBEM from "hooks/useBEM";
import {useCharacterTabs} from "contexts/CharacterTabs";
import {Tabs} from "@mantine/core";
import PreviewTab from "components/CharacterApp/interface/CharacterTabs/PreviewTab";

export default function SecondaryTabs() {
  const classes = useBEM('character-tabs');
  const {activeTab, setActiveTab} = useCharacterTabs();

  return <Tabs classNames={{
    root: classes(),
    panel: classes('panel'),
    tab: classes('tab'),
  }} value={activeTab} onChange={setActiveTab} keepMounted={false}>
    <PreviewTab/>
  </Tabs>
}