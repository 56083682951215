import "styles/components/character-app/_character-tabs.scss";
import {useCharacterTabs} from "contexts/CharacterTabs";
import {Tabs} from "@mantine/core";
import SkinColorTab from "components/CharacterApp/interface/CharacterTabs/SkinColorTab";
import EyeColorTab from "components/CharacterApp/interface/CharacterTabs/EyeColorTab";
import HairColorTab from "components/CharacterApp/interface/CharacterTabs/HairColorTab";
import CharacterTab from "components/CharacterApp/interface/CharacterTabs/CharacterTab";
import HairTab from "components/CharacterApp/interface/CharacterTabs/HairTab";
import useBEM from "hooks/useBEM";
import MaskColorTab from "components/CharacterApp/interface/CharacterTabs/MaskColorTab";
import MaskTab from "components/CharacterApp/interface/CharacterTabs/MaskTab";
import OutfitColorTab from "components/CharacterApp/interface/CharacterTabs/OutfitColorTab";
import CapeColorTab from "./CapeColorTab";
import AccessoryColorTab from "./AccessoryColorTab";
import BadgeColorTab from "./BadgeColorTab";
import AccessoryTab from "./AccessoryTab";
import CapeTab from "./CapeTab";
import SaveTab from "components/CharacterApp/interface/CharacterTabs/SaveTab";
import NameTab from "components/CharacterApp/interface/CharacterTabs/NameTab";
import PowersTab from "components/CharacterApp/interface/CharacterTabs/PowersTab";
import RenderTab from "components/CharacterApp/interface/CharacterTabs/RenderTab";

export default function CharacterTabs() {
  const classes = useBEM('character-tabs');
  const {activeTab, setActiveTab} = useCharacterTabs();

  return <Tabs classNames={{
    root: classes(),
    panel: classes('panel'),
    tab: classes('tab'),
  }} value={activeTab} onChange={setActiveTab} keepMounted={false}>
    <CharacterTab/>
    <SkinColorTab/>
    <HairTab/>
    <HairColorTab/>
    <EyeColorTab/>
    <MaskTab/>
    <MaskColorTab/>
    <CapeTab/>
    <CapeColorTab/>
    <AccessoryTab/>
    <AccessoryColorTab/>
    <OutfitColorTab/>
    <BadgeColorTab/>
    <NameTab/>
    <PowersTab/>
    <SaveTab/>
    <RenderTab/>
  </Tabs>
}
