import {useMemo} from "react";
import CharacterOptions from "components/CharacterApp/interface/CharacterOptions";
import NoHairIcon from "images/character-app/no-hair.png";
import Hair01Icon from "images/character-app/hair-01.png";
import Hair02Icon from "images/character-app/hair-02.png";
import Hair03Icon from "images/character-app/hair-03.png";
import Hair04Icon from "images/character-app/hair-04.png";
import Hair05Icon from "images/character-app/hair-05.png";
import Hair06Icon from "images/character-app/hair-06.png";
import Hair07Icon from "images/character-app/hair-07.png";
import Hair08Icon from "images/character-app/hair-08.png";
import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function HairTab() {
  const options = useMemo(() => {
    return [
      {
        value: false,
        title: 'No hair',
        icon: NoHairIcon,
      },
      {
        value: '01',
        title: 'Short hair',
        icon: Hair01Icon,
      },
      {
        value: '02',
        title: 'Short curly hair',
        icon: Hair02Icon,
      },
      {
        value: '03',
        title: 'Bob cut',
        icon: Hair03Icon,
      },
      {
        value: '04',
        title: 'Medium hair',
        icon: Hair04Icon,
      },
      {
        value: '05',
        title: 'Ponytail',
        icon: Hair05Icon,
      },
      {
        value: '06',
        title: 'Spiky hair',
        icon: Hair06Icon,
      },
      {
        value: '07',
        title: 'Spiky hair',
        icon: Hair07Icon,
      },
      {
        value: '08',
        title: 'Spiky hair',
        icon: Hair08Icon,
      },
    ];
  }, []);

  return <CharacterTabsPanel value={'hair'}>
    <CharacterOptions name={'hair'} options={options}/>
  </CharacterTabsPanel>
}
