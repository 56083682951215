import ColorPicker from "components/CharacterApp/interface/ColorPicker";
import {useCallback, useMemo} from "react";
import {useCharacterParams} from "contexts/CharacterParams";
import CharacterTabsPanel, {CharacterTabsPanelFooter} from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function SkinColorTab() {
  const { colors, setColor } = useCharacterParams();
  const availableColors = useMemo(() => {
    return [
      {
        color: '#F0E1CD',
      },
      {
        color: '#EBD3BC',
      },
      {
        color: '#DDC2A0',
      },
      {
        color: '#CFAE87',
      },
      {
        color: '#B8946A',
      },
      {
        color: '#A47B52',
      },
      {
        color: '#8B714D',
      },
      {
        color: '#785939',
      },
      {
        color: '#61422A',
      },
      {
        color: '#452D1A',
      },
    ];
  }, []);

  const handleChange = useCallback((color) => {
    setColor('skin', color);
  }, [setColor]);

  return <CharacterTabsPanel value={'character_color'}>
    <ColorPicker colors={availableColors} color={colors.skin} onChange={handleChange} allowMetallic={false} allowSlider={false}/>
    <CharacterTabsPanelFooter>
      <ColorPicker colors={availableColors} color={colors.skin} onChange={handleChange} allowMetallic={false} allowSwatches={false}/>
    </CharacterTabsPanelFooter>
  </CharacterTabsPanel>
}
