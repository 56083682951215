import "styles/components/_button.scss";
import {ActionIcon} from "@mantine/core";
import useBEM from "hooks/useBEM";

export default function IconButton(props) {
  const classes = useBEM('button');
  return <ActionIcon classNames={{
    root: classes({
      'icon': true,
    }),
    icon: classes('icon'),
  }} {...props}/>
}
