export default function ColorIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.6154 13.1887C35.3077 12.8045 34.6923 12.8045 34.3846 13.1887C31.7692 16.57 22 29.7879 22 36.0126C22 43.1595 27.8462 49 35 49C42.1538 49 48 43.1595 48 36.0126C48 29.7879 38.2308 16.57 35.6154 13.1887ZM39.3846 44.466C39.2308 44.5428 39.0769 44.5428 38.9231 44.5428C38.4615 44.5428 38 44.2354 37.8462 43.8512C37.6154 43.2364 37.9231 42.6216 38.4615 42.3142C43 40.4698 42.6154 34.9368 42.5385 34.8599C42.4615 34.2451 42.9231 33.6303 43.6154 33.6303C44.2308 33.5535 44.8462 34.0146 44.8462 34.7062C45.1538 37.1654 44.2308 42.4679 39.3846 44.466Z"
      fill="#696969"/>
    <path
      d="M35.6154 12.2869C35.3077 11.9044 34.6923 11.9044 34.3846 12.2869C31.7692 15.6534 22 28.8134 22 35.0108C22 42.1263 27.8462 47.9412 35 47.9412C42.1538 47.9412 48 42.1263 48 35.0108C48 28.8134 38.2308 15.6534 35.6154 12.2869ZM39.3846 43.427C39.2308 43.5035 39.0769 43.5035 38.9231 43.5035C38.4615 43.5035 38 43.1975 37.8462 42.8149C37.6154 42.2028 37.9231 41.5907 38.4615 41.2847C43 39.4484 42.6154 33.9396 42.5385 33.8631C42.4615 33.251 42.9231 32.6389 43.6154 32.6389C44.2308 32.5624 44.8462 33.0215 44.8462 33.7101C45.1538 36.1584 44.2308 41.4377 39.3846 43.427Z"
      fill="white"/>
  </svg>
}
