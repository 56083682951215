export default function CapeIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.9255 19.6061L31.1489 19.714C29.1526 19.8219 27.2643 20.4693 25.6457 21.5484C23.9732 22.6814 23.7034 23.059 22.3007 24.5157C16.258 32.2309 15.3948 46.0428 16.258 47.3916C17.445 49.2799 20.5202 44.1544 27.3182 45.9349C35.9506 48.093 40.105 44.5321 42.8565 45.6651L43.6658 24.5697C43.6658 24.5697 42.5868 20.5233 41.9933 19.714L34.9255 19.6061Z"
      fill="#696969"/>
    <path
      d="M43.6658 24.5696H40.9142L40.9682 32.8783L40.8603 44.8017L42.8565 45.665C47.1187 46.2584 49.3847 47.0138 48.8452 45.7189C47.7122 42.5897 45.0685 30.936 43.6658 24.5696Z"
      fill="#696969"/>
    <path
      d="M30.1777 33.6337L28.9368 48.4167C28.8289 49.6036 29.7461 50.7366 30.987 50.7906C32.1739 50.8985 33.3069 50.0352 33.3609 48.7404L34.5478 34.5509H35.4111L36.598 48.7404C36.7059 49.9273 37.6231 50.7906 38.8101 50.7906H38.9719C40.1589 50.6827 41.0761 49.6036 41.0221 48.4167L39.8352 33.6337L39.7273 23.4907H49.9782C51.0573 23.4907 51.9745 22.5735 51.9745 21.4944C51.9205 20.4693 51.0573 19.6061 49.9243 19.6061H34.9255C27.2643 19.6061 23.7034 24.7316 23.5416 24.9474C23.056 25.6487 23.1099 26.5659 23.5955 27.2134L27.0485 32.0691M30.2317 29.5873L27.8577 26.1883C28.3973 25.6487 29.2066 25.0553 30.2856 24.5697L30.2317 29.5873Z"
      fill="#696969"/>
    <path
      d="M34.9255 18.9046C37.3689 18.9046 39.3496 16.9239 39.3496 14.4805C39.3496 12.0371 37.3689 10.0564 34.9255 10.0564C32.4821 10.0564 30.5014 12.0371 30.5014 14.4805C30.5014 16.9239 32.4821 18.9046 34.9255 18.9046Z"
      fill="#696969"/>
    <path
      d="M34.9255 18.7286L31.1489 18.8365C29.1526 18.9444 27.2643 19.5919 25.6457 20.6709C23.9732 21.8039 23.7034 22.1816 22.3007 23.6383C16.258 31.3535 15.3948 45.1653 16.258 46.5141C17.445 48.4025 20.5202 43.277 27.3182 45.0574C35.9506 47.2155 40.105 43.6546 42.8565 44.7877L43.6658 23.6923C43.6658 23.6923 42.5868 19.6458 41.9933 18.8365L34.9255 18.7286Z"
      fill="white"/>
    <path
      d="M43.6658 23.6921H40.9142L40.9682 32.0008L40.8603 43.9243L42.8565 44.7875C47.1187 45.381 49.3847 46.1363 48.8452 44.8415C47.7122 41.7122 45.0685 30.0585 43.6658 23.6921Z"
      fill="white"/>
    <path
      d="M30.1777 32.7563L28.9368 47.5392C28.8289 48.7262 29.7461 49.8592 30.987 49.9131C32.1739 50.021 33.3069 49.1578 33.3609 47.8629L34.5478 33.6735H35.4111L36.598 47.8629C36.7059 49.0499 37.6231 49.9131 38.8101 49.9131H38.9719C40.1589 49.8052 41.0761 48.7262 41.0221 47.5392L39.8352 32.7563L39.7273 22.6132H49.9782C51.0573 22.6132 51.9745 21.696 51.9745 20.617C51.9205 19.5919 51.0573 18.7286 49.9243 18.7286H34.9255C27.2643 18.7286 23.7034 23.8541 23.5416 24.0699C23.056 24.7713 23.1099 25.6885 23.5955 26.3359L27.0485 31.1916M30.2317 28.7098L27.8577 25.3108C28.3973 24.7713 29.2066 24.1778 30.2856 23.6923L30.2317 28.7098Z"
      fill="white"/>
    <path
      d="M34.9255 18.0272C37.3689 18.0272 39.3496 16.0464 39.3496 13.6031C39.3496 11.1597 37.3689 9.17896 34.9255 9.17896C32.4821 9.17896 30.5014 11.1597 30.5014 13.6031C30.5014 16.0464 32.4821 18.0272 34.9255 18.0272Z"
      fill="white"/>
  </svg>
}
