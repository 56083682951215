export const hexToRgb = hex => {
  let alpha = false,
    h = hex.slice(hex.startsWith('#') ? 1 : 0);
  if (h.length === 3) h = [...h].map(x => x + x).join('');
  else if (h.length === 8) alpha = true;
  h = parseInt(h, 16);
  return {
    r: h >>> (alpha ? 24 : 16),
    g: (h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8),
    b: (h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0),
    a: alpha ? (h & 0x000000ff) : 1,
  };
};

export const rgbToHex = ({r, g, b}) =>
  '#' + Math.round((r << 16) + (g << 8) + b).toString(16).padStart(6, '0');

export const rgbToHsb = ({r, g, b}) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const v = Math.max(r, g, b),
    n = v - Math.min(r, g, b);
  const h =
    n === 0 ? 0 : n && v === r ? (g - b) / n : v === g ? 2 + (b - r) / n : 4 + (r - g) / n;
  return {
    h: 60 * (h < 0 ? h + 6 : h),
    s: v && (n / v) * 100,
    b: v * 100,
  };
};

export const hsbToRgb = ({h, s, b}) => {
  s /= 100;
  b /= 100;
  const k = (n) => (n + h / 60) % 6;
  const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
  return {
    r: 255 * f(5),
    g: 255 * f(3),
    b: 255 * f(1)
  };
};

export const hexToHsb = hex => rgbToHsb(hexToRgb(hex));
export const hsbToHex = hsb => rgbToHex(hsbToRgb(hsb));
