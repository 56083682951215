export default function OutfitIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.955 36.6733C54.6175 36.4285 54.2801 36.2326 53.9908 36.0857L37.1182 26.9282C37.1182 26.5854 37.1182 26.3406 37.1182 26.1447C39.4804 24.3328 41.4087 20.3662 40.5409 17.379C40.2517 16.3506 39.1429 14 35.3827 14C31.6225 14 28.7783 18.4073 28.7783 20.709C28.7783 23.0106 29.5978 22.5209 30.562 22.5209C31.5261 22.5209 32.3456 21.6884 32.3456 20.709C32.3456 19.7295 32.4903 17.6238 35.3827 17.6238C36.5634 17.6946 37.0218 18.1625 37.07 18.3584C37.5039 19.7785 36.0576 22.4719 34.7078 23.3534C33.7919 23.941 33.4062 25.1163 33.4544 26.9772L16.7264 36.0367C16.3889 36.1836 16.0997 36.3795 15.7622 36.6244C14.6053 37.4079 12.8698 39.2688 13.4483 41.2276C13.7375 42.207 14.6535 43.3333 17.2567 43.3333H53.4124C56.0156 43.3333 56.9315 42.207 57.2208 41.2276C57.7992 39.2688 56.0156 37.4079 54.9068 36.6244L54.955 36.6733ZM52.9303 39.7095H17.8352C18.028 39.5626 18.269 39.4647 18.4619 39.3177L35.3827 30.1603L52.3036 39.3177C52.5446 39.4157 52.7375 39.5626 52.9303 39.7095Z"
      fill="#696969"/>
    <path
      d="M54.955 35.6733C54.6175 35.4285 54.2801 35.2326 53.9908 35.0857L37.1182 25.9282C37.1182 25.5854 37.1182 25.3406 37.1182 25.1447C39.4804 23.3328 41.4087 19.3662 40.5409 16.379C40.2517 15.3506 39.1429 13 35.3827 13C31.6225 13 28.7783 17.4073 28.7783 19.709C28.7783 22.0106 29.5978 21.5209 30.562 21.5209C31.5261 21.5209 32.3456 20.6884 32.3456 19.709C32.3456 18.7295 32.4903 16.6238 35.3827 16.6238C36.5634 16.6946 37.0218 17.1625 37.07 17.3584C37.5039 18.7785 36.0576 21.4719 34.7078 22.3534C33.7919 22.941 33.4062 24.1163 33.4544 25.9772L16.7264 35.0367C16.3889 35.1836 16.0997 35.3795 15.7622 35.6244C14.6053 36.4079 12.8698 38.2688 13.4483 40.2276C13.7375 41.207 14.6535 42.3333 17.2567 42.3333H53.4124C56.0156 42.3333 56.9315 41.207 57.2208 40.2276C57.7992 38.2688 56.0156 36.4079 54.9068 35.6244L54.955 35.6733ZM52.9303 38.7095H17.8352C18.028 38.5626 18.269 38.4647 18.4619 38.3177L35.3827 29.1603L52.3036 38.3177C52.5446 38.4157 52.7375 38.5626 52.9303 38.7095Z"
      fill="white"/>
  </svg>
}
