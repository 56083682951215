import "styles/components/_scroll-area.scss";
import {ScrollArea as CoreScrollArea} from "@mantine/core";
import useBEM from "hooks/useBEM";
import {useCallback, useRef} from "react";

export default function ScrollArea({onWheel, ...props}) {
  const classes = useBEM('scroll-area');
  const viewport = useRef();

  const handleWheel = useCallback((e) => {
    if (typeof onWheel === 'function') {
      onWheel.call(this, e);
      return;
    }

    if (props.scrollbars !== 'x' || !viewport.current || e.deltaY === 0 || e.deltaX !== 0) {
      return;
    }

    const delta = e.deltaY;
    const currPos = viewport.current.scrollLeft;
    const scrollWidth = viewport.current.scrollWidth;
    viewport.current.scrollLeft = Math.max(0, Math.min(scrollWidth, currPos + delta));
  }, [onWheel, props.scrollbars]);

  return <CoreScrollArea classNames={{
    root: classes(),
    'viewport': classes('viewport'),
    scrollbar: classes('scrollbar'),
    thumb: classes('thumb'),
  }} type={'auto'} viewportRef={viewport} onWheel={handleWheel} {...props}/>
}
