import {useMemo} from "react";
import CharacterOptions from "components/CharacterApp/interface/CharacterOptions";
import NoMaskIcon from "images/character-app/no-mask.png";
import MaskEyes01Icon from "images/character-app/mask-eyes-01.png";
import MaskEyes02Icon from "images/character-app/mask-eyes-02.png";
import MaskFaceIcon from "images/character-app/mask-full-face.png";
import MaskHeadIcon from "images/character-app/mask-full-head.png";
import MaskStarIcon from "images/character-app/mask-star.png";
import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function MaskTab() {
  const options = useMemo(() => {
    return [
      {
        value: false,
        title: 'No mask',
        icon: NoMaskIcon,
      },
      {
        value: 'eyes01',
        title: 'Eyes mask #1',
        icon: MaskEyes01Icon,
      },
      {
        value: 'eyes02',
        title: 'Eyes mask #2',
        icon: MaskEyes02Icon,
      },
      {
        value: 'fullface',
        title: 'Full face mask',
        icon: MaskFaceIcon,
      },
      {
        value: 'fullhead',
        title: 'Full head mask',
        icon: MaskHeadIcon,
      },
      {
        value: 'star',
        title: 'Star mask',
        icon: MaskStarIcon,
      },
    ];
  }, []);

  return <CharacterTabsPanel value={'mask'}>
    <CharacterOptions name={'mask'} options={options}/>
  </CharacterTabsPanel>
}
