/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 public/assets/models/hair/hair05.glb
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import useCharacterMaterials from "hooks/useCharacterMaterials";
import useCharacterMorphs from "hooks/useCharacterMorphs";
import {PUBLIC_URL} from "helpers/app";

export function Hair05(props) {
  const { nodes, materials } = useGLTF(PUBLIC_URL + '/assets/models/hair/hair05.glb');
  const characterMaterials = useCharacterMaterials(materials);
  useCharacterMorphs(nodes);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Hair05.geometry} material={characterMaterials.Hair} position={[0, 1.289, -0.098]} rotation={[0.047, 0, 0]}>
        <mesh geometry={nodes.Scrunchy.geometry} material={characterMaterials.SuitSecondary} position={[0, -0.06, 0.492]} rotation={[1.588, 0, 0]} />
        <mesh geometry={nodes.Tail.geometry} material={characterMaterials.Hair} position={[0, -0.061, 0.516]} rotation={[-1.557, 0, 0]} />
        <mesh name="Fringes" geometry={nodes.Fringes.geometry} material={characterMaterials.Hair} morphTargetDictionary={nodes.Fringes.morphTargetDictionary} morphTargetInfluences={nodes.Fringes.morphTargetInfluences} />
      </mesh>
    </group>
  )
}

useGLTF.preload(PUBLIC_URL + '/assets/models/hair/hair05.glb')
