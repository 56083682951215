import "styles/components/character-app/_form.scss";
import useBEM from "hooks/useBEM";
import TextInput from "components/interface/TextInput";
import {Radio, RadioGroup} from "components/interface/Radio";
import {useCallback} from "react";
import {useCharacterParams} from "contexts/CharacterParams";
import CharacterTabsPanel from "components/CharacterApp/interface/CharacterTabs/CharacterTabsPanel";

export default function NameTab() {
  const classes = useBEM('character-tabs');
  const {parameters, setParameter} = useCharacterParams();

  const handleRadios = useCallback((name) => {
    if (name) {
      return (value) => {
        setParameter(name, value);
      };
    }
  }, [setParameter]);

  const handleChange = useCallback((e) => {
    if (e?.target?.name) {
      setParameter(e.target.name, e.target.value);
    }
  }, [setParameter]);

  return <CharacterTabsPanel value={'name'}>
    <div className={classes('panel-inner')}>
      <h4 className={classes('panel-title')}>Name Your Little Hero!</h4>
      <div className={'form'}>
        <TextInput label={'Enter your superheroes name:'} placeholder={'Kid Altrini'} name={'name'} pattern={'[A-Za-z ]'}
                   value={parameters.name} onChange={handleChange} maxLength={16}/>

        <TextInput label={'Add your superheroes catch phrase:'} placeholder={'Fuelling futures, one bottle at a time!'} pattern={'[A-Za-z !]'}
                   name={'tagline'} value={parameters.tagline} onChange={handleChange} maxLength={80}/>

        <RadioGroup name={'flavour'} label={'Select your favourite Altrini flavour:'} defaultValue={'strawberry'}
                    value={parameters.flavour} onChange={handleRadios('flavour')}>
          <Radio label={'Super Strawberry'} value={'strawberry'}/>
          <Radio label={'Valiant Vanilla'} value={'vanilla'}/>
        </RadioGroup>
      </div>
    </div>
  </CharacterTabsPanel>
}
