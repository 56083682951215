import "styles/components/character-app/_power-slider.scss";
import useBEM from "hooks/useBEM";
import {useCallback, useMemo} from "react";
import BottleIcon from "icons/BottleIcon";
import IconButton from "components/interface/IconButton";
import Button from "components/interface/Button";
import AddIcon from "icons/AddIcon";
import RemoveIcon from "icons/RemoveIcon";

export default function PowerSlider({id, title, value, onChange, max = 4, count = 4, flavour = 'strawberry'}) {
  const classes = useBEM('power-slider');
  max = Math.min(max, count);

  const handleClick = useCallback((index) => {
    return () => {
      let newValue;
      if (value === index + 1) {
        newValue = index;
      }
      else{
        newValue = index + 1;
      }
      newValue = Math.min(newValue, max);
      onChange && onChange(newValue);
    }
  }, [value, max, onChange]);

  const handleValue = useCallback((value) => {
    return () => {
      onChange && onChange(Math.max(Math.min(value, max), 0));
    }
  }, [onChange, max]);

  const items = useMemo(() => {
    const result = [];
    for (let i = 0; i < count; i++) {
      const isActive = (i <= value - 1);
      result.push(<IconButton key={id + '_' + i} className={classes('item', {
        active: isActive,
      })} onClick={handleClick(i)} disabled={i >= max}>
        <BottleIcon full={isActive}/>
      </IconButton>);
    }
    return result;
  }, [classes, id, value, count, max, handleClick]);

  return <div className={classes({
    flavour,
  })}>
    {title && <div className={classes('label')}>{title + ':'}</div>}
    <Button variant={'round'} className={classes('button', {less: true})} onClick={handleValue(value - 1)} disabled={value <= 0}><RemoveIcon/></Button>
    <div className={classes('items')}>
      {items}
    </div>
    <Button variant={'round'} className={classes('button', {more: true})} onClick={handleValue(value + 1)} disabled={value >= max}><AddIcon/></Button>
  </div>
}
